import React from "react";
import { Button } from "react-bootstrap";
import LeaderboardRank from "./leaderboardRank";
import LiveUrlLinks from "./liveUrlLinks";

export default function workoutDetailsUsers({
  workoutsDetails,
  workoutSummary,
  leaderBoard,
  handleModalOpen
}) {
  return (
    <div className="row">
      <div className="col-sm-12 text-center">
        <h2 className="page-heading">Trainer Dashboard</h2>
        {workoutsDetails.isRecordingInProgress === false && (
          <div
            className="delete-button-position"
            onClick={handleModalOpen}
          >
            <Button variant="outline-danger">Delete</Button>
          </div>
        )}
      </div>
      <div className="col-sm-10 m-auto">
        <div className="row">
          <LiveUrlLinks
            workoutSummary={workoutSummary}
            streamKey={workoutsDetails.streamKey}
            liveContentUrl={workoutsDetails.liveContentUrl}
            vodContentUrl={workoutsDetails.vodContentUrl}
          />
          <LeaderboardRank leaderBoard={leaderBoard} />
        </div>
      </div>
    </div>
  );
}
