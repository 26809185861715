import React from "react";
import Dropdown from "./../../common/dropdown";

export default function workoutFormDropdown({
  items,
  dropTitle,
  title,
  i,
  name,
  toggleName,
  handleItemRoundDifficulty,
  roundsError,
}) {
  return (
    <div className="col-sm-6">
      <div className="each-detail">
        <label className="workout-details-page">{dropTitle}</label>
        <Dropdown
          items={items}
          title={title}
          name={name}
          toggleItem={(e) =>
            handleItemRoundDifficulty(e, i, toggleName)
          }
          error={roundsError}
        />
        <span className="error-message">
          <small>{roundsError}</small>
        </span>
      </div>
    </div>
  );
}
