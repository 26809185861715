import React from "react";
import TotalDuration from "../WorkoutComponent/totalDuration";
import ActivityDetails from "./activityDetails";
import StrikesDetails from "./strikesDetails";
import WorkoutDuration from "./workoutDuration";

export default function workoutRoundDetails({
  workoutsDetails,
  comboList,
  strikesList,
  movesList,
  activityList,
}) {
  return (
    <div className="row mt-50">
      <div className="col-sm-12 m-auto">
        <TotalDuration
          duration={workoutsDetails.duration}
          roundsLength={workoutsDetails.rounds?.length}
        />
        {workoutsDetails.rounds &&
          workoutsDetails.rounds.map((x, i) => {
            return (
              <div key={i} className="round-box">
                <WorkoutDuration data={x} />
                {x.workoutTypeId === 3 || x.workoutTypeId === 4 ? (
                  <StrikesDetails
                    data={x}
                    comboList={comboList}
                    strikesList={strikesList}
                    movesList={movesList}
                  />
                ) : null}

                {x.workoutTypeId === 1 ||
                x.workoutTypeId === 2 ||
                x.workoutTypeId === 5 ? (
                  <div className="activity-list-border">
                    <div className="row">
                      <div className="col-sm-6">
                        <label className="workout-details-page">
                          Activities
                        </label>
                      </div>
                      <div className="col-sm-6">
                        <label className="workout-details-page">
                          Duration(Sec)
                        </label>
                      </div>
                    </div>
                    <ActivityDetails
                      activities={x.Activities}
                      activityList={activityList}
                      workoutTypeId={[x.workoutTypeId]}
                    />
                  </div>
                ) : null}
              </div>
            );
          })}
      </div>
    </div>
  );
}
