import React, { Component } from "react";
import Slider from "react-slick";
import CardComponent from "./../CardComponent/cardComponent";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "./sliderComponent.css";

class SliderComponent extends Component {
  state = {};

  render() {
    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 4,
      arrows: true,
    };

    const { itemList } = this.props;

    const workout = itemList.map((data, id) => {
      return <CardComponent key={id} item={data} type={this.props.type} />;
    });

    return <Slider {...settings}>{workout}</Slider>;
  }
}

export default SliderComponent;
