import React from 'react';

const SelectedValues = (props) => {
    let stackvalue = 10;
    let isCombo = props.dropdownName === "combos" ? true : false;
    let items = props.values.map((item, i) => {
        if ((isCombo && i < 1) || (!isCombo && i < 3)) {
            return (<span key={item.id} className="account-user-name commaSeparatedSpan">{item.name}</span>);
        }
        return "";
    })

    if (props && props.values && ((isCombo && props.values.length > 1) || (!isCombo && props.values.length > 3))) {
        let limitValue = isCombo ? 1 : 3;
        items.push(<span key={stackvalue} className="account-user-name commaSeparatedSpan">+{(props.values.length - limitValue)} Items</span>);
    }

    return items;
}

export default SelectedValues;
