import { useState } from "react";
import { uploadPoster, updatePoster } from "../services/userService";
import Compressor from 'compressorjs';

export default function useUploadPoster(
  isDetails = false,
  params,
  updateWorkoutPoster
) {
  const [posterUrl, setPosterUrl] = useState(
    "https://cdn3.iconfinder.com/data/icons/eldorado-stroke-symbols/40/shape_square-512.png"
  );
  const [posterImageErrId, setPosterImageErrId] = useState(0);
  const [fileinputGroupSuccess, setFileinputGroupSuccess] = useState("");
  const [posterError, setPosterError] = useState("");

  const fileChangedHandler = (event) => {
    const file = event.target.files[0];
    if (!file) {
      return;
    }

    if (/\.(jpe?g|png|gif)$/i.test(file.name) === false) {
      setPosterError("Allowed Image formats are JPG, JPEG, PNG");
      return;
    }
    console.log(file);
    new Compressor(file, {
      quality: 0.6,
      maxWidth: 1920,
      maxHeight: 1920,
      success(result) {
        console.log(result);
        setPosterError("Uploading ...");
        setFileinputGroupSuccess("fileinputGroupSuccess");
        const UpdatedFileSet = new File([result], file.name, {
          type: file.type,
          lastModified: file.lastModified,
        });
        uploadPoster(UpdatedFileSet).then((res) => {
          if (res && res.data) {
            if (isDetails) {
              updateWorkoutPoster(res.data.url, "posterUrl");
            } else {
              setPosterUrl(res && res.data === null ? posterUrl : res.data.url);
            }
          }
          setPosterImageErrId(1);
          setPosterError("");
          setFileinputGroupSuccess("");

          if (isDetails) {
            const workoutId = parseInt(params ? params.workoutId : 0);
            if (res && res.data && res.data.url) {
              setPosterError("Updating Thumbnail....");
              setFileinputGroupSuccess("fileinputGroupSuccess");
              updatePoster(res.data.url, workoutId).then((res) => {
                if (res && res.data) {
                  setPosterError("Success");
                  setFileinputGroupSuccess("fileinputGroupSuccess");
                  setTimeout(() => {
                    setPosterError(1);
                    setPosterError("");
                    setFileinputGroupSuccess("");
                  }, 1000);
                } else {
                  setPosterError("something went wrong!!");
                }
              });
            }
          }
        });
      }});
  };
  return [posterError, fileinputGroupSuccess, fileChangedHandler, setPosterUrl, posterUrl, posterImageErrId];
}
