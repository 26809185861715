import React from "react";
import config from "../../config";
import WorkoutFormDropdown from "./workoutFormDropdown";

export default function workoutTypeDetails({
  workoutTypeId,
  roundDifficulty,
  index,
  workoutTypeErr,
  difficultyErr,
  handleItemRoundSelected,
  handleItemRoundDifficulty,
}) {
  return (
    <div className="row">
      <WorkoutFormDropdown
        dropTitle="Workout Type"
        title={
          workoutTypeId
            ? config.workoutTypeIdObj[workoutTypeId]
            : "Workout Types"
        }
        name="workoutTypeList"
        toggleName="workoutTypeId"
        i={index}
        handleItemRoundDifficulty={handleItemRoundSelected}
        roundsError={workoutTypeErr}
        items={config.workoutTypeList}
      />
      <WorkoutFormDropdown
        dropTitle="Round Difficulty"
        title={
          roundDifficulty
            ? config.roundDifficultyObj[roundDifficulty]
            : "Select Options"
        }
        name="roundDifficulty"
        toggleName="roundDifficulty"
        i={index}
        handleItemRoundDifficulty={handleItemRoundDifficulty}
        roundsError={difficultyErr}
        items={config.roundDifficulty}
      />
    </div>
  );
}
