import React from "react";
import DashboardPage from "../components/DashboardPage/dashboardPage";
import "../components/DashboardPage/dashboardComponent.css";

function DashboardComponent() {
  return (
      <DashboardPage />
  );
}

export default DashboardComponent;
