let websockets = {};

function getSocket(workoutId) {
	if (websockets[workoutId] && websockets[workoutId].readyState !== WebSocket.CLOSED) {
		return websockets[workoutId];
	} else {
		let userToken = localStorage.getItem("_token_");
		let ws = new WebSocket(process.env.REACT_APP_SOCKET_URL + "/" + workoutId, ["jwt", userToken]);
		websockets[workoutId] = ws;
		return ws;
	}
}

export default getSocket;