export const calculateDuration = (roundsArr) => {
  const coolDownTime = roundsArr.reduce(
    (prev, next) =>
      prev + (isNaN(parseFloat(next.coolDownTime)) ? 0 : next.coolDownTime),
    0
  );
  const warmUpTime = roundsArr.reduce(
    (prev, next) =>
      prev + (isNaN(parseFloat(next.warmUpTime)) ? 0 : next.warmUpTime),
    0
  );

  let totalSeconds = warmUpTime + coolDownTime;
  let minsCalc = totalSeconds; //Math.floor(totalSeconds % 3600 / 60).toString().padStart(2, '0')

  const duration = roundsArr.reduce(
    (prev, next) =>
      prev + (isNaN(parseFloat(next.duration)) ? 0 : next.duration),
    0
  );
  return duration * 60 + parseInt(minsCalc);
};