import React from "react";

export default function totalDuration({ duration, roundsLength }) {
  return (
    <div className="clearfix round-details-holder">
      <div className="pull-left header-top">Round Details</div>
      <div className="pull-right ">
        Workout Duration:{" "}
        <b>
          {parseInt(duration / 60) || `00`} mins {duration % 60 || `00`} sec
        </b>
      </div>
      <div className="pull-right mr-40">
        Total Round: <b>{roundsLength ? roundsLength : 0}</b>
      </div>
    </div>
  );
}
