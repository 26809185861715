import React from "react";

export default function leaderboardRank({ leaderBoard }) {
  const getRank = (rank) => {
    if (rank === 1) {
      return "trophy gold";
    }
    if (rank === 2) {
      return "trophy silver";
    }
    return "trophy bronze";
  };
  return (
    <div className="col-sm-12 col-md-8">
      <div className="right-list">
        <div className="table-title">Leaderboard</div>
        <div className="table-responsive">
          <table className="table table-sm">
            <thead>
              <tr>
                <th>Place</th>
                <th></th>
                <th># of Strikes</th>
              </tr>
            </thead>
            <tbody>
              {leaderBoard && leaderBoard.length > 0 &&
                leaderBoard.map((leaders, i) => (
                  <tr key={i}>
                    <td>
                      #{leaders.rank}
                      {leaders.rank < 4 ? (
                        <span className={getRank(leaders.rank)}>
                          <i className="fa fa-trophy" aria-hidden="true"></i>
                        </span>
                      ) : (
                        ""
                      )}
                    </td>
                    <td>{leaders.name}</td>
                    <td>{leaders.strike}</td>
                  </tr>
                ))}
              {leaderBoard.length < 1 ? (
                <tr>
                  <td colSpan="3" style={{ textAlign: "center", color: "red" }}>
                    No Results
                  </td>
                </tr>
              ) : (
                <tr />
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
