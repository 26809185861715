import React from "react";

export default function addActivity({
  workoutTypeId,
  index,
  handleAddActivity,
}) {
  return (
    <>
      {workoutTypeId === 1 || workoutTypeId === 2 || workoutTypeId === 5 ? (
        <div className="clearfix">
          <div
            onClick={(e) => handleAddActivity(e, index)}
            className="pull-right btn add-round-btn"
          >
            <span>+ Add Activity</span>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
}
