import React, { useState, useEffect } from "react";
import { login } from "../../services/authService";
import { withRouter } from "react-router-dom";
import EmailField from "./emailField";
import SubmitButton from "./submitButton";
import useLoginForm from "../../hooks/useLoginForm";
import FormComponent from "./formComponent";

const LoginForm = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [errormsg, setErrormsg] = useState("");

  const [data, errors, handleChange, handleSubmitValidations] =
    useLoginForm(true);

  useEffect(() => {
    const getQuery = () => {
      return new URLSearchParams(window.location.search);
    };
    const expQuery = getQuery().get("expired");
    let expiredError = expQuery !== null && parseInt(expQuery) === 1 ? 1 : 0;
    if (expiredError === 1) {
      setErrormsg("Token expired. Please login again");
    }
  }, []);

  const handleRegister = () => {
    props.history.push("/register");
  };

  const handleVal = (e) => {
    e.preventDefault();
    let { passwordError, emailError } = { ...errors };
    let { password, email } = data;
    let isValid = true;
    if (emailError || !email) {
      emailError = emailError || "Please Enter Eamil ID";
      isValid = false;
    }
    if (passwordError || !password) {
      passwordError = passwordError || "Please Enter Password";
      isValid = false;
    }

    if (isValid) {
      doSubmit();
    } else {
      handleSubmitValidations(passwordError, emailError);
    }
  };

  const doSubmit = async () => {
    try {
      let { password, email } = data;
      setIsLoading(true);
      const { data: user, headers: token } = await login({
        userName: email,
        password: password,
      });
      if (user.code !== 200) {
        setErrormsg(user.message);
        setIsLoading(false);
        return;
      }
      const { firstName, lastName, roleId } = user.data;
      setIsLoading(false);
      localStorage.setItem("_token_", token.authorization);
      localStorage.setItem("_type_", roleId);
      localStorage.setItem("_name_", `${firstName} ${lastName}`);
      localStorage.setItem("_user-email_", user.data.email);
      localStorage.setItem("_user-id_", user.data.id);
      props.loggedIn();
    } catch (error) {
      setIsLoading(false);
      console.log("Login Failed", error);
    }
  };

  const { email, password } = data;
  const { passwordError, emailError } = errors;
  return (
    <FormComponent
      isLoading={isLoading}
      handleSubmit={handleVal}
      pageErrors={errormsg}
      formClassNames="logo"
    >
      <EmailField
        name="email"
        username={email}
        password={password}
        handleChange={handleChange}
        usernameError={emailError}
        passwordError={passwordError}
      />
      <SubmitButton
        isLoading={isLoading}
        submitBtnText="Login"
        backBtnText="sign up"
        handleRegister={handleRegister}
        btnClassName="btn btn-lg btn-block text-uppercase"
        registerBtn={false}
      />
    </FormComponent>
  );
};

export default withRouter(LoginForm);
