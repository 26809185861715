import React from "react";
import Dropdown from "./../../common/dropdown";
import SearchDropDown from "./../../common/searchDropDown";
import Form from "../../common/form";
import "./UpdateUserForm.css";
import { UpdatePermissions } from "../../services/userService";
import "react-datepicker/dist/react-datepicker.css";
import "../../common/style.css";
import Enums from "../../config";

class UpdateUserForm extends Form {
  state = {
    overlay: false,
    show: true,
    searchinit: "",
    data: {
      user: "",
      roleList: "",
    },
    errors: {
      userError: "",
      roleError: "",
      isFormValid: true,
    },
    currentRole: "",
    pageErrors: "",
    pageSucess: "",
    roleList: [
      {
        id: 2,
        value: "Admin",
        name: "Admin",
      },
      {
        id: 3,
        value: "Trainer",
        name: "Trainer",
      },
      {
        id: 4,
        value: "Trainee",
        name: "Trainee",
      },
    ],
  };

  handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let { userError, roleError } = this.state.errors;
    switch (name) {
      case "user":
        break;
      default:
        break;
    }

    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        [name]: value,
      },

      errors: {
        ...prevState.errors,
        userError,
        roleError,
      },
    }));
  };

  handleItemSelected = (id, value, name, selectedValues) => {
    const { data } = { ...this.state };
    const { errors } = { ...this.state };
    data[name] = id;
    errors["roleError"] = "";
    this.setState({ data: data });
  };

  handleClearItem = (name) => {
    const { data } = { ...this.state };
    data[name] = "";
    this.setState({ data: data, currentRole: null });
  };

  handleSearchItemSelected = (id, value, name, role) => {
    this.handleClearItem(name);
    const { data } = { ...this.state };
    const { errors } = { ...this.state };
    data[name] = id;
    name === "role" ? (errors["roleError"] = "") : (errors["userError"] = "");
    this.setState({ data: data, currentRole: Enums.Roles[role] });
  };

  handleButtonSubmit = (e) => {
    var errorsList = "";
    const { errors } = { ...this.state };
    if (!this.state.data.roleList) {
      errorsList = "yes";
      errors["roleError"] = "No modifications made";
      this.setState({ errors: errors });
    }
    if (!this.state.data.user) {
      errorsList = "yes";
      errors["userError"] = "Required";
      this.setState({ errors: errors });
    }

    if (errorsList) {
      window.scrollTo(0, 0);
      return;
    }

    const requestBody = {
      userId: this.state.data.user,
      roleId: this.state.data.roleList,
    };

    UpdatePermissions(requestBody).then((res) => {
      if (res && res.code === 200) {
        const { data } = { ...this.state };
        data.roleList = data.user = "";
        this.setState({ pageSucess: "Role Updated Sucessfully" });
        this.setState({ currentRole: "", data: data, overLay: true });
        setTimeout(() => {
          this.setState({ pageSucess: "", pageError: "", show: false });
          this.setState({
            pageSucess: "",
            pageError: "",
            show: true,
            overLay: false,
          });
        }, 4000);
      } else {
        this.setState({
          pageErrors: res.message,
        });
        window.scrollTo(0, 0);
      }
    });
  };

  overLay = (props) => {
    return props && <div className="overlay-container"></div>;
  };

  render() {
    const dropdownConfig = { isMultiSelect: false };
    const { roleList } = this.state;
    return (
      <React.Fragment>
        {this.state.overLay && <div className="overlay-container"></div>}
        {this.state.show && (
          <div className="container-fluid workout">
            <div className="row">
              <div className="col-sm-10 col-md-10 m-auto workout-container">
                <div className="logo"></div>
                <div className="row content-center">
                  <h2 className="col-sm-12 page-heading">
                    Update User Permission
                  </h2>
                  <div className="col-sm-12 page-errors">
                    {this.state.pageErrors}
                  </div>
                  <div className="col-sm-12 page-sucess">
                    {this.state.pageSucess}
                  </div>
                  <div className="col-sm-8">
                    <div className="row content-center">
                      <div className="col-sm-6">
                        <div className="each-detail">
                          <label className="workout-details-page">
                            Search User
                          </label>
                          <SearchDropDown
                            config={dropdownConfig}
                            name="user"
                            title="Search..."
                            toggleItem={this.handleSearchItemSelected}
                            clearItem={this.handleClearItem}
                            autoComplete="off"
                            value={this.state.searchinit}
                          />
                          <span className="error-message">
                            <small>{this.state.errors.userError}</small>
                          </span>
                        </div>
                      </div>
                    </div>
                    {this.state.currentRole && (
                      <div className="row content-center">
                        <div className="col-sm-6">
                          <div className="each-detail">
                            <label className="workout-details-page">Role</label>
                            <Dropdown
                              config={dropdownConfig}
                              items={roleList}
                              role="true"
                              title={this.state.currentRole}
                              name="roleList"
                              toggleItem={this.handleItemSelected}
                            />
                            <span className="error-message">
                              <small>{this.state.errors.roleError}</small>
                            </span>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className="row round-details mt-50">
                  <div className="col-sm-9 m-auto">
                    <div className=" text-center">
                      <button
                        className="btn create"
                        onClick={this.handleButtonSubmit}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default UpdateUserForm;
