import React from "react";
import Dropdown from "./../../common/dropdown";
import config from "../../config";
import DatePicker from "react-datepicker";

export default function workoutDatePicker({
  levelError,
  levelName,
  dateError,
  startDate,
  handleItemSelected,
  handleDateTimeChange,
}) {
  const dropdownConfig = { isMultiSelect: false };
  return (
    <div className="row">
      <div className="col-sm-6">
        <div className="each-detail">
          <label className="workout-details-page">Starts at</label>
          <div className="input-group date" id="datetimepicker1">
            <div
              className="react-date-width"
              style={{
                outline: dateError ? "2px solid red" : "none",
              }}
            >
              <DatePicker
                selected={startDate}
                onChange={handleDateTimeChange}
                showTimeSelect
                timeIntervals={15}
                minDate={new Date()}
                className="form-control"
                dateFormat="MM/dd/yyyy hh:mm aa"
              />
            </div>
            <i className="fa fa-calendar" aria-hidden="true"></i>
            <span className="error-message">
              <small>{dateError}</small>
            </span>
          </div>
        </div>
      </div>

      <div className="col-sm-6">
        <div className="each-detail">
          <label className="workout-details-page">Difficulty</label>
          <Dropdown
            config={dropdownConfig}
            items={config.workoutLevelList}
            title={levelName ? config.workoutLevel[levelName] : "Workout Level"}
            name="workoutLevelList"
            toggleItem={handleItemSelected}
            error={levelError}
          />
          <span className="error-message">
            <small>{levelError}</small>
          </span>
        </div>
      </div>
    </div>
  );
}
