import React from 'react';

import Aux from "../hoc/hoc";

const layout = (props) => (
    <Aux>
        <main role="main">
            {props.children}
        </main>
    </Aux>
)

export default layout;
