import React from "react";
import Dropdown from "./../../common/dropdown";
import { Input } from "../../common/input";
import "react-datepicker/dist/react-datepicker.css";
import "../../common/style.css";

const ActivityComponent = ({
  i,
  data,
  handleActivity,
  handleActivityDuration,
  hanldeRemove,
  errors,
  strikesList = [],
  handleItemCommonSelected,
  combosList = [],
  moves_list = [],
  handleRadioButtonChange,
  handleChangedefensive,
  combosError,
  strikesError,
  movesError,
  activityList = [],
}) => {
  const handleGetActivityName = (id) => {
    if (activityList && activityList?.length > 0) {
      const res = activityList?.find((data) => data.id === id);
      return res ? res.name : "Select Options";
    }
    return "Select Options";
  };
  return (
    <div>
      {data.workoutTypeId === 1 ||
      data.workoutTypeId === 2 ||
      data.workoutTypeId === 5 ? (
        data.activity_info?.map((data, index) => (
          <div key={index} className="row activity">
            <div className="col-sm-6">
              <label className="workout-details-page">Activity</label>
              <Dropdown
                items={activityList || []}
                title={data.activityListId ? handleGetActivityName(data.activityListId) : "Select Options"}
                name="activityType"
                toggleItem={(e) => handleActivity(e, i, index, "activityListId")}
                error={errors && errors[index]?.activityError}
              />
              <span className="error-message">
                <small>
                  {errors && errors[index]?.activityError ? "Required" : ""}
                </small>
              </span>
            </div>
            <div className="col-sm-4">
              <div className="each-detail">
                <label className="workout-details-page">Duration (Sec)</label>
                <input
                  className={
                    errors && errors[index]?.durationError
                      ? `error-outline-input`
                      : undefined
                  }
                  readOnly={!data.activityListId}
                  type="number"
                  name="activityTime"
                  value={data.durationSec}
                  min="30"
                  onChange={(e) => handleActivityDuration(e, i, index, "durationError")}
                />
                <span className="error-message">
                  <small>
                    {errors && errors[index]?.durationError ? "Required" : ""}
                  </small>
                </span>
              </div>
            </div>
            {/* <div className="clearfix"> */}
            <div
              className="remove-activity"
              onClick={(e) => hanldeRemove(e, i, index)}
            >
              Remove
            </div>
          </div>
          // </div>
        ))
      ) : (
        <>
          <div className="row">
            <div className="col-sm-6">
              <div className="each-detail">
                <label className="workout-details-page">Strikes</label>
                <Dropdown
                  multi
                  items={strikesList}
                  title="Select Options"
                  name="strikes"
                  values={data.strikes}
                  roundId={i}
                  toggleItem={handleItemCommonSelected}
                  error={strikesError}
                />
                <span className="error-message">
                  <small>{strikesError}</small>
                </span>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="each-detail">
                <label className="workout-details-page">Combos</label>
                <Dropdown
                  multi
                  items={combosList || []}
                  title="Select Options"
                  name="combos"
                  values={data.combos}
                  roundId={i}
                  toggleItem={handleItemCommonSelected}
                  error={combosError}
                />
                <span className="error-message">
                  <small>{combosError}</small>
                </span>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6">
              <div className="each-detail">
                <label className="workout-details-page">Moves</label>
                <div disabled={data.disabled}>
                  <Dropdown
                    multi
                    items={moves_list || []}
                    title="Moves Types"
                    name="moves"
                    values={data.moves}
                    roundId={i}
                    toggleItem={handleItemCommonSelected}
                    error={movesError}
                  />
                </div>
                <span className="error-message">
                  <small>{movesError}</small>
                </span>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="each-detail">
                <label className="workout-details-page">Defensive Moves</label>
                <div
                  className="btn-group-toggle radio-buttons"
                  data-toggle="buttons"
                  onChange={(e) => handleRadioButtonChange(e, i)}
                >
                  <label
                    className={`btn btn-primary defensiveMoves ${
                      data.isDefensive ? " active" : ""
                    }`}
                  >
                    <Input
                      name="defensiveMoves"
                      type="radio"
                      placeholder="Yes"
                      checked={data.isDefensive}
                      value="1"
                      onChange={handleChangedefensive}
                    />
                    Yes
                  </label>
                  <label
                    className={`btn btn-primary defensiveMoves ${
                      !data.isDefensive ? " active" : ""
                    }`}
                  >
                    <Input
                      name="defensiveMoves"
                      type="radio"
                      placeholder="No"
                      value="0"
                      checked={!data.isDefensive}
                      onChange={handleChangedefensive}
                    />
                    No
                  </label>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ActivityComponent;
