import React from "react";

export default function roundDurationDetails({ duration,roundsLength }) {
  return (
    <div className="clearfix round-details-holder">
      <div className="pull-left header-top">Round Details</div>
      <div className="pull-right ">
        Workout Duration:{" "}
        <b>
          {parseInt(duration / 60)} mins {duration % 60} sec
        </b>
      </div>
      <div className="pull-right mr-40">
        Total Round: <b>{roundsLength}</b>
      </div>
    </div>
  );
}
