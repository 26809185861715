import React, { Component } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Layout from "./components/Layouts/Layout";
import LoginComponent from "./container/LoginContainer";
import Dashboard from "./container/DashboardContainer";
import Logout from "./components/logout";
import Workout from "./container/WorkoutContainer";
import WorkoutForm from "./components/WorkoutComponent/workoutFormHook";
import RegistrationForm from "./components/TrainerComponent/registrationForm";
import Header from "./components/HeaderComponent/HeaderComponent";
import "./App.css";
import UpdateUserForm from "./components/UpdateUserComponent/UpdateUserForm";
import LiveDashboard from "./components/DashboardPage/livePage";

class App extends Component {
  state = {
    isLoggedinUser: false,
  };
  role = localStorage.getItem("_type_");

  componentDidMount() {
    const token = localStorage.getItem("_token_");

    if (token) {
      this.setState({ isLoggedinUser: true });
    }
  }

  loggedIn() {
    const token = localStorage.getItem("_token_");

    if (token) {
      this.setState({ isLoggedinUser: true });
    }
  }

  render() {
    const { isLoggedinUser } = this.state;
    let routes = (
      <Switch>
        <Route path="/login">
          <LoginComponent loggedIn={this.loggedIn.bind(this)} />
        </Route>
        <Route path="/register" component={RegistrationForm}></Route>
        <Route path="/">
          <LoginComponent loggedIn={this.loggedIn.bind(this)} />
        </Route>
      </Switch>
    );
    if (isLoggedinUser) {
      routes = (
        <Switch>
          {+this.role === 2 ? (
            <Route path="/update-user" component={UpdateUserForm}></Route>
          ) : (
            ""
          )}

          <Route path="/workout/create" component={WorkoutForm}></Route>
          <Route
            path="/workout/details/:workoutId"
            component={Dashboard}
          ></Route>
          <Route
            path="/workout/edit/:workoutId"
            component={WorkoutForm}
          ></Route>
          <Route
            path="/workout/live/:workoutId"
            component={LiveDashboard}
          ></Route>
          <Route path="/workout" component={Workout}></Route>
          <Route path="/logout" component={Logout}></Route>

          <Route path="/">
            <Redirect to="/workout" />
          </Route>
        </Switch>
      );
    }

    return (
      <React.Fragment>
        <Header isLoggedin={isLoggedinUser} />
        <div className="container-fluid">
          <Layout>{routes}</Layout>
        </div>
      </React.Fragment>
    );
  }
}

export default App;
