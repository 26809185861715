import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import config from "../../config";
import Loader from "../../common/loader";
import { deleteWorkout } from "../../services/userService";
import { convertDateAddOffset } from "../../utils/convertTimeZone";
import useWorkoutDetails from "../../hooks/useWorkoutDetails";
import useModal from "../../hooks/useModal";
import getSocket from "../../services/socketService";
import WorkoutPoster from "../WorkoutComponent/workoutPoster";
import useUploadPoster from "../../hooks/useUploadPoster";
import MMAInfo from "../WorkoutComponent/MMAInfo";
import WorkoutDetailsUsers from "./workoutDetailsUsers";
import ColDetails from "./colDetails";
import WorkoutRoundDetails from "./workoutRoundDetails";
import Modal from "../../common/modal";

function DashboardPage(props) {
  const [
    workoutsDetails,
    strikesList,
    movesList,
    activityList,
    comboList,
    isLoading,
    remainingTime,
    updateWorkoutPoster,
    setIsLoading,
  ] = useWorkoutDetails(props.match.params, props.history);

  const [leaderBoard, setLeaderBoard] = useState([]);
  const [workoutSummary, setWorkoutSummary] = useState({
    usersOnline: 0,
    usersAttended: 0
  });

  let ws = getSocket(props.match.params.workoutId);

  ws.onopen = () => {
    console.log("connected websocket main component");
    ws.send('{"event": 1}');
    console.log("Socket Open");
  };

  ws.onclose = (e) => {
    console.log("Socket is closed", e.reason);
  }

  ws.onerror = (err) => {
    console.error(
      "Socket encountered error: ",
      err.message
    );
  };

  ws.onmessage = (event) => {
    console.log("Got message: " + event.data);
    let responseDatas = JSON.parse(event.data);

    if (responseDatas && responseDatas.event === 1) {
      setLeaderBoard(responseDatas.data.leaderboard.leaders);
      setWorkoutSummary({
        usersOnline: responseDatas.data.userCount,
        usersAttended: responseDatas.data.userCount
      });
    }
  }

  const [posterError, fileinputGroupSuccess, fileChangedHandler] =
    useUploadPoster(true, props.match.params, updateWorkoutPoster);

  const [modalDetails, handleModalOpen, handleModalClose] = useModal();


  const formatTime = (date, placeholder = false) => {
    const dt = new Date(date);
    const istTime = placeholder ? dt : convertDateAddOffset(dt);
    const DateString = `${istTime.getFullYear()}-${
      istTime.getMonth() + 1
    }-${istTime.getDate()} ${
      istTime.getHours() === 0
        ? 12
        : `${istTime.getHours() < 10 ? `0` : ``}${istTime.getHours()}`
    }:${(istTime.getMinutes() < 10 ? "0" : "") + istTime.getMinutes()}`;
    return DateString;
  };

  const handleAction = (confirmation) => {
    const requestBody = {
      id: props.match.params.workoutId,
    };
    handleModalClose();
    if (confirmation) {
      setIsLoading(true);
      deleteWorkout(requestBody)
        .then((res) => {
          setIsLoading(false);
          if (res && res.code !== 200) {
            window.scrollTo(0, 0);
          } else {
            props.history.push("/workout");
          }
        })
        .catch((err) => {
          setIsLoading(false);
        });
    }
  };

  const { show, title, msg } = modalDetails;
  return (
    <>
      <Modal
        title={title}
        msg={msg}
        handleAction={handleAction}
        handleModalClose={handleModalClose}
        show={show}
      />
      {isLoading && <Loader />}
      <div className="container-fluid dashboard">
        {remainingTime.min !== null && (
          <div className="col-sm-12 text-center mt-25 remaining-time-counter">
            {remainingTime.min} min {remainingTime.sec} sec remaining
          </div>
        )}
        <WorkoutDetailsUsers
          workoutsDetails={workoutsDetails}
          workoutSummary={workoutSummary}
          leaderBoard={leaderBoard}
          handleModalOpen={handleModalOpen}
        />
        <div className="row">
          <div className="col-sm-10 col-md-10 m-auto workout-container">
            <div className="logo"></div>
            <div className="row">
              <WorkoutPoster
                posterUrl={workoutsDetails.posterUrl}
                fileChangedHandler={fileChangedHandler}
                fileinputGroupSuccess={fileinputGroupSuccess}
                posterError={posterError}
              />
              <div className="col-sm-8">
                <MMAInfo
                  mmaVal={workoutsDetails.isMMA}
                  padVal={workoutsDetails.padTypeId}
                  handleRadioPadsChange={() => {}}
                  handleRadioMmaChange={() => {}}
                  hideInput
                />

                <div className="row">
                  <ColDetails
                    label="Starts at"
                    value={
                      workoutsDetails.startsAt
                        ? formatTime(workoutsDetails.startsAt)
                        : formatTime(new Date(), true)
                    }
                  />
                  <ColDetails
                    label="Difficulty"
                    value={
                      config.workoutLevel[workoutsDetails.levelId] ||
                      `Difficulty`
                    }
                  />
                </div>
                <WorkoutRoundDetails
                  workoutsDetails={workoutsDetails}
                  comboList={comboList}
                  strikesList={strikesList}
                  movesList={movesList}
                  activityList={activityList}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default withRouter(DashboardPage);
