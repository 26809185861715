import React from "react";
import ReactHlsPlayer from 'react-hls-player';

const TabletView = React.memo(({ liveContentUrl }) => {
	return (
		<div className="tab-view-wrapper">
			<h2>Live</h2>
			<div className="tab-view-container col">
				{liveContentUrl ?
					<ReactHlsPlayer
						src={liveContentUrl}
						autoPlay={true}
						controls={false}
						muted={true}
						width="100%"
						height="100%"
						hlsConfig={{
							lowLatencyMode: true,
							capLevelToPlayerSize: true,
							backBufferLength: 0
						}}
					/> :
					<div>Tab View</div>
				}
			</div>
		</div>
	);
});

export default TabletView;
export { TabletView };