import React from 'react'
import Logo from "../images/loader.gif"
import "./style.css"

export default function Loader() {
    return (
        <div className="loader">
           <img src={Logo} alt="logo" />
        </div>
    )
}
