import React from "react";
import FormRadioButtons from "./formRadioButtons";

export default function MMAInfo({
  mmaVal,
  padVal,
  handleRadioPadsChange,
  handleRadioMmaChange,
  hideInput=false
}) {
  return (
    <div className="row">
      <div className="col-sm-4">
        <div className="each-detail ">
          <label className="workout-details-page">Number of Pads</label>
          <div
            className="btn-group-toggle radio-buttons"
            data-toggle="buttons"
            onChange={handleRadioPadsChange}
          >
            <FormRadioButtons
              activeValue={1}
              name="padTypeVal"
              checkedValue={padVal}
              placeholder="Yes"
              value="3"
              hideInput={hideInput}
            />

            <FormRadioButtons
              activeValue={2}
              name="padTypeVal"
              checkedValue={padVal}
              placeholder="No"
              value="5"
              isNotChecked={true}
              hideInput={hideInput}
            />
          </div>
        </div>
      </div>
      <div className="col-sm-4 mma">
        <div className="each-detail">
          <label className="workout-details-page">MMA? </label>
          <div
            className="btn-group-toggle radio-buttons"
            data-toggle="buttons"
            onChange={handleRadioMmaChange}
          >
            <FormRadioButtons
              activeValue={hideInput ? true : 1}
              name="mma"
              checkedValue={mmaVal}
              placeholder="No"
              value="Yes"
              hideInput={hideInput}
            />
            <FormRadioButtons
              activeValue={hideInput ? false : 0}
              name="mma"
              checkedValue={mmaVal}
              placeholder="No"
              value="No"
              isNotChecked={true}
              hideInput={hideInput}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
