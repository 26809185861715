import { useState, useEffect, useRef } from "react";
import {
  workoutDetails,
  strikesListApi,
  combosListApi,
  activityListApi,
  movesListApi,
} from "../services/userService";
import { convertDateAddOffset } from "../utils/convertTimeZone";

export default function useWorkoutDetails(matchParams, history, live = false) {
  const [workoutsDetails, setWorkoutsDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [strikesList, setStrikesList] = useState({});
  const [activityList, setActivityList] = useState([]);
  const [comboList, setComboList] = useState({});
  const [movesList, setMovesList] = useState({});
  const [remainingTime, setRemainingTime] = useState({ min: null, sec: null });

  const ref = useRef(null);

  useEffect(() => {
    const workoutIds = parseInt(matchParams ? matchParams.workoutId : 0);
    setIsLoading(true);

    workoutDetails(workoutIds)
      .then((res) => {
        setIsLoading(false);
        if (res && res.data && res.data !== null) {
          let response = res.data;
          ref.current = setInterval(() => {
            if (new Date() >= convertDateAddOffset(new Date(response?.startsAt)) &&
            new Date() <= convertDateAddOffset(new Date(response?.endsAt))) {
              clearInterval(ref.current);
              history.push(`/workout/live/${workoutIds}`);
            } else {
              if (
                new Date() > convertDateAddOffset(new Date(response?.endsAt))
              ) {
                clearInterval(ref.current);
              }
            }
            if (res && res.data && res.data.startsAt) {
              let time = convertDateAddOffset(new Date(res.data.startsAt));
              let remaining = time.getTime() - new Date().getTime();
              let min = Math.floor(remaining / 1000 / 60);
              if (min < 10 && min >= 0) {
                let sec = Math.floor((remaining / 1000) % 60);
                let timeobj = { min, sec };
                setRemainingTime(timeobj);
              }
            }
          }, 1000 / 60);

          let moves = {};
          let strikes = {};
          strikesListApi(response.levelId, response.padTypeId)
            .then((res) => {
              if (res && res.data) {
                res.data.map((data) => (strikes[data.id] = data));
                setStrikesList(strikes);
              }
            })
            .catch((err) => console.log("Something went wrong"));

          if (!live) {
            movesListApi(response.levelId)
              .then((res) => {
                if (res && res.data) {
                  res.data.map((data) => (moves[data.id] = data));
                  setMovesList(moves);
                }
              })
              .catch((err) => console.log("Something went wrong"));
          }

          const updateDashboardDetails = (levelId, padVal) => {
            let promiseList
            let listOrder
            if(live){
              promiseList = [
                activityListApi(levelId, padVal, 1),
                activityListApi(levelId, padVal, 2),
                activityListApi(levelId, padVal, 5),
              ];
              listOrder = ["Cardio", "Strike", "Core"];
            }else{
              promiseList = [
                combosListApi(levelId, padVal, false),
                combosListApi(levelId, padVal, true),
                activityListApi(levelId, padVal, 1),
                activityListApi(levelId, padVal, 2),
                activityListApi(levelId, padVal, 5),
              ];
              listOrder = [1, 2, "Cardio", "Strike", "Core"];
            }
            let activities = {};
            let combos = {};
            let name
            setIsLoading(true)
            Promise.all(promiseList)
              .then((values) => {
                setIsLoading(false)
                values.map((data, index) => {
                  name = listOrder[index]
                  if (!live && (index === 0 || index === 1)) {
                    combos[name] = {};
                    for (let index = 0; index < data?.data?.length; index++) {
                      const resName = data?.data[index].id;
                      combos[name][resName] = data.data[index];
                    }
                  } else {
                    activities[name] = {};
                    for (let index = 0; index < data?.data?.length; index++) {
                      const resName = data.data[index].id;
                      activities[name][resName] = data.data[index];
                    }
                  }
                  return true;
                });
                setComboList(combos);
                setActivityList(activities);
              })
              .catch(() => {
                setIsLoading(false)
              });
          };

          updateDashboardDetails(response.levelId, response.padTypeId)
        }
        setWorkoutsDetails(res && res.data ? res.data : []);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("Something went wrong");
      });
    return () => {
      if (ref.current) {
        clearInterval(ref.current);
      }
    }
  }, [history, matchParams, live]);

  const updateWorkoutPoster = (url, name) => {
    setWorkoutsDetails((prev) => ({
      ...prev,
      [name]: url,
    }));
  };

  return [
    workoutsDetails,
    strikesList,
    movesList,
    activityList,
    comboList,
    isLoading,
    remainingTime,
    updateWorkoutPoster,
    setIsLoading
  ];
}
