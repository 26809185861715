import React from "react";

export default function durationComponent({roundsError,handleRoundDetailsChange,data,i}) {
  return (
    <div className="row">
      <div className="col-sm-4">
        <div className="each-detail">
          <label className="workout-details-page">Duration (Min)</label>
          <input
            className={
              roundsError && roundsError[i]?.durationError
                ? `error-outline-input`
                : undefined
            }
            readOnly={
                data.roundDifficulty && data.roundDifficulty > 0 ? false : true
            }
            type="number"
            name="duration"
            placeholder="Duration"
            value={data.duration}
            onChange={(e) => handleRoundDetailsChange(e, i)}
            min="1"
          />
          <span className="error-message">
            <small>
              {roundsError &&
                roundsError[i]?.durationError}
            </small>
          </span>
        </div>
      </div>
      <div className="col-sm-4">
        <div className="each-detail">
          <label className="workout-details-page">Cooldown Time (Sec)</label>
          <input
            className={
             roundsError && roundsError[i]?.coolDownError
                ? `error-outline-input`
                : undefined
            }
            readOnly={data.roundDifficulty > 0 ? false : true}
            type="number"
            name="coolDown"
            placeholder="Cool Down time"
            value={data.coolDownTime}
            onChange={(e) => handleRoundDetailsChange(e, i)}
            min="30"
          />
          <span className="error-message">
            <small>
              {roundsError &&
                roundsError[i]?.coolDownError}
            </small>
          </span>
        </div>
      </div>
      <div className="col-sm-4">
        <div className="each-detail">
          <label className="workout-details-page">Warmup Time (Sec)</label>
          <input
            className={
              roundsError && roundsError[i]?.warmUpError
                ? `error-outline-input`
                : undefined
            }
            readOnly={data.roundDifficulty > 0 ? false : true}
            type="number"
            name="warmUp"
            placeholder="Warm Up time"
            value={data.warmUpTime}
            min="30"
            onChange={(e) => handleRoundDetailsChange(e, i)}
          />
          <span className="error-message">
            <small>
              {roundsError && roundsError[i]?.warmUpError}
            </small>
          </span>
        </div>
      </div>
    </div>
  );
}
