import axios from "axios";

// function setAuthToken(token) {
//     axios.defaults.headers.common["x-auth-token"] = token;
// }

export default {
    get: axios.get,
    post: axios.post,
    // setAuthToken
    delete: axios.delete,
}