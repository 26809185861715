import React, { Component } from "react";
import { Link } from "react-router-dom";
import SelectedValues from "./dropdownSelectedValue";
import "../common/style.css";

class Dropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menuOpen: false,
    };
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  isEmpty = (obj) => {
    for(var key in obj) {
        if(obj.hasOwnProperty(key))
            return false;
    }
    return true;
}

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({ menuOpen: false });
    }
  }

  handleClick = (e) => {
    this.setState({ menuOpen: !this.state.menuOpen });
  };

  handleSelectItem = (id, value, items) => {
    const props = this.props;
    let menuListOpen = props.multi ? true : !this.state.menuOpen;
    this.props.toggleItem(id,"", this.props.name, "", this.props.roundId);
    this.setState({  menuOpen: menuListOpen });
  };

  inputChangedHandler = (event) => {
    // eslint-disable-next-line
    const updatedKeyword = event.target.value;
  };

  renderCheckbox = (item,checkedValue) => {
    return <input
      onChange={(event) => this.inputChangedHandler(event)}
      type="checkbox"
      checked={checkedValue}
    />
  }

  render() {
    const props = this.props;
    const handleComboList = (values) => {
      if(values && values?.length > 0){
        const res = values?.filter(data => this.props.values[data.id] ? true : false)
        return res
      }
      return []
    }

    const items = this.props.items.map((item) => {
      let checkbox = "";
      if (props.multi) {
          const checkedValue = this.props.values[item.id] ? true : false
        checkbox = this.renderCheckbox(item,checkedValue);
        return (
          <div
            className="dropdown-item"
            key={item.id}
            data-key={item.id}
            data-value={item.name}
            onClick={() =>
              this.handleSelectItem(item.id, item.name, item.selected)
            }
          >
            {checkbox} {item.name}
          </div>
        );
      } else if (props.isLink) {
        return (
          <Link className="dropdown-item" key={item.name} to={item.link}>
            {item.name}
          </Link>
        );
      }

      return (
        <div
          className="dropdown-item"
          key={item.id}
          data-key={item.id}
          data-value={item.name}
          onClick={() => this.handleSelectItem(item.id, item.name)}
        >
          {item.name}
        </div>
      );
    });

    let classNameDynamic =
      "dropdown-custom btn-outline-secondary dropdown-toggle col-md-12";
    classNameDynamic =
      typeof this.props.fromLocation === "undefined"
        ? classNameDynamic
        : "btn btn-outline-secondary dropdown-toggle col-md-12";

    return (
      <div className="dropdown" ref={this.setWrapperRef}>
        <div
          className={`${classNameDynamic} dropdown-ellipse-selected ${props.error && `error-outline-dropdown`}`}
          role="button"
          id="userInfoDropdownLink"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          onClick={this.handleClick}
        >
          {this.props.config && this.props.config.imagePath && (
            <span>
              <img
                src={this.props.config.imagePath}
                alt=""
                className="rounded-circle"
              />
            </span>
          )}
          {!this.isEmpty(this.props.values) && (
            this.props.items && this.props.items?.length > 0 ?
            <SelectedValues
              dropdownName={this.props.name}
              values={handleComboList(this.props.items)}
            />
            :
            "Select Options"
          )}
          {this.isEmpty(this.props.values) && !props.role && (
            <span className="account-user-name">{this.props.title}</span>
          )}
          {/* props role for reseting dropdown on search User for update permission form */}
          {this.isEmpty(this.props.values) && props.role && (
            <span className="account-user-name">{props.title}</span>
          )}
        </div>
        {this.state.menuOpen && (
          <div
            className="dropdown-menu show dropdown_menu_show"
            aria-labelledby="userInfoDropdownLink"
          >
            {items}
          </div>
        )}
      </div>
    );
  }
}

export default Dropdown;
