import React from "react";
import { Modal, Button } from "react-bootstrap";
import PropTypes from "prop-types";

const modal = ({ title, msg, handleModalClose, show, handleAction }) => {
  return (
    <Modal show={show} onHide={handleModalClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{msg}</Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => {
            handleAction(false);
          }}
        >
          No
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            handleAction(true);
          }}
        >
          Yes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

modal.propTypes = {
  title: PropTypes.string,
  msg: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
  handleModalClose: PropTypes.func.isRequired,
  handleAction: PropTypes.func.isRequired
};

export default modal;
