import React from "react";

export default function WorkoutPoster({
  posterUrl,
  fileChangedHandler,
  fileinputGroupSuccess,
  posterError,
}) {
  return (
    <div className="col-sm-4">
      <div className="file-field form-file-field">
        <div className="file-holder z-depth-1-half mb-2">
          <img
            src={posterUrl}
            className="edit-workout-image"
            alt="placeholder"
          />
        </div>
        <form>
          <div className="input-group fileinputGroup">
            <div className="custom-file">
              <input
                type="file"
                className="custom-file-input"
                id="inputGroupFile01"
                aria-describedby="inputGroupFileAddon01"
                onChange={fileChangedHandler}
              />
              <label className="custom-file-label" htmlFor="inputGroupFile01">
                Choose file
              </label>
            </div>
          </div>
        </form>
        <span
          className={`error-message fileinputGroup ${fileinputGroupSuccess}`}
        >
          <small>{posterError}</small>
        </span>
        <div className="leavet-it-blank">
          NOTE: Leave It Blank For Default Poster
        </div>
      </div>
    </div>
  );
}
