export const handleActivityDurationDifference = (round) => {
  if (
    round.duration > 0 &&
    (round?.workoutTypeId === 1 ||
      round?.workoutTypeId === 2 ||
      round?.workoutTypeId === 5)
  ) {
    let total = 0;
    total = round.activity_info.reduce(
      (prev, curr) => prev + parseInt(curr.durationSec || 0),
      0
    );
    
    return round.duration * 60 - total;
  }
};
