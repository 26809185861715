import React from "react";

const Input = ({ type, name, value, onChange, placeHolder, min, autoComplete, password }) => {
  return (
    <div className="form-group">
      <input
        value={value}
        onChange={onChange}
        type={type}
        className={'form-control ' + (password ? 'input-password ' : '')}
        id={name}
        name={name}
        placeholder={placeHolder}
        min={min}
        autoComplete={`${autoComplete}`}
      />
    </div>
  );
};


const Select = ({ name, options, placeHolder, onChange, isMulti, disabled }) => {
  const optionItems = options.map((item) => {
    return (<option value={item.value} key={item.id}>{item.value}</option>)
  });

  return (<select class="form-control" name={name} onChange={onChange} data-isMulti={isMulti} disabled={disabled}>{optionItems}</select>);
};

export { Input, Select };
