import { useState } from "react";

export default function useModal() {
  const [modalDetails, setModalDetails] = useState({
    show: false,
    msg: "Are you sure you want to delete this workout?",
    title: "Delete Workout",
    confirm: false,
  });

  const handleModalClose = () => {
    setModalDetails((prev) => ({
      ...prev,
      show: false,
    }));
  };

  const handleModalOpen = () => {
    setModalDetails((prev) => ({
      ...prev,
      show: true,
    }));
  };

  return [modalDetails, handleModalOpen, handleModalClose]
}
