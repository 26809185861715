import React from "react";
import { Link } from "react-router-dom";
import Dropdown from "./../../common/dropdown";

function Header(props) {
  const dropdownConfig = {
    isImage: true,
    imagePath: "/images/user.jpg",
  };
  const logout = {
    name: "Logout",
    link: "/logout",
  };
  const updateUser = { name: "Update User", link: "update-user" };

  const menuList = [];
  const userName = localStorage.getItem("_name_");
  const role = localStorage.getItem("_type_");
  if (+role === 2) {
    menuList.push(updateUser);
    menuList.push(logout);
  } else {
    menuList.push(logout);
  }

  return (
    <div>
      {props.isLoggedin && (
        <nav className="navbar navbar-expand-lg fixed-top navbar-dark bg-dark">
          <div></div>
          <Link to="/workout">
            {/* <a className="navbar-brand"  href="javascript:void(0);"> */}
            <img
              alt="logo"
              src="https://472d8k2rdaaa1pstff391x82-wpengine.netdna-ssl.com/wp-content/uploads/2018/11/cropped-white-logo-newest.png"
              height="30"
            />
            {/* </a> */}
          </Link>

          <div
            className="collapse navbar-collapse left-menu"
            id="navbarNavDropdown"
          >
            {/* <ul className="navbar-nav">
          <li className="nav-item">
                    <Link className="nav-link" to="/">Home</Link>
                </li>
          {props.isLoggedin && (
            <React.Fragment>
              <li className="nav-item">
                <Link className="nav-link" to="/workout">
                  Workout
                </Link>
              </li>
            </React.Fragment>
          )}

          {!props.isLoggedin && (
            <li className="nav-item">
              <Link className="nav-link" to="/login">
                Login
              </Link>
            </li>
          )}
        </ul> */}
          </div>
          {props.isLoggedin && (
            <div className="header-right-menu">
              <div className="user-info">
                <Dropdown
                  config={dropdownConfig}
                  isLink={true}
                  items={menuList}
                  title={userName}
                  fromLocation="userinfo"
                >
                  {/* <div class="dropdown-menu" aria-labelledby="userInfoDropdownLink">
                                <Link class="dropdown-item" to="/logout">Logout</Link>
                            </div> */}
                </Dropdown>
              </div>
            </div>
          )}
        </nav>
      )}
    </div>
  );
}

export default Header;
