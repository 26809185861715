import React, { Component } from "react";
import { searchUser } from "../services/userService";

class SearchInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menuOpen: false,
      headerTitle: this.props.title,
      dropdownName: this.props.name,
      fromLocation: this.props.fromLocation ? this.props.fromLocation : "",
      strikesInitial: this.props.strikesInitial ? this.props.strikesInitial : 0,
      combosInitial: this.props.combosInitial ? this.props.combosInitial : 0,
      movesInitial: this.props.movesInitial ? this.props.movesInitial : 0,
      id: "",
      items: [{ id: 0, text: "searching...." }],
      value: "",
    };
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }
  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
    this.handleOnSearch("");
    this.setState({ value: this.props.value });
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  setWrapperRef = (node) => {
    this.wrapperRef = node;
  };

  handleClickOutside = (event) => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({ menuOpen: false });
    }
  };

  handleSelectItem = (id, valueOne, valueTwo, role) => {
    const { dropdownName } = this.state;
    const value = valueOne + " " + valueTwo;
    let menuListOpen = !this.state.menuOpen;
    this.props.toggleItem(id, value, dropdownName, role);
    this.setState({ menuOpen: menuListOpen, value: value, id: id });
  };

  handleClearItem = (name) => {
    if (name) {
      this.props.clearItem(name);
      this.setState({ menuOpen: true, value: "", id: "" });
    } else {
      this.props.clearItem(this.props.name);
      this.setState({ menuOpen: true, id: "" });
    }
  };

  handleClick = (e) => {
    this.setState({ menuOpen: true });
  };

  handleOnSearch = (value) => {
    if (value) {
      this.handleClearItem();
    }

    const RequestBody = {
      searchText: value,
    };
    this.setState({ value: value });
    searchUser(RequestBody).then((res) => {
      if (res && res.code !== 200) {
        window.scrollTo(0, 0);
      } else if (res === null) {
        this.setState({ items: [{ id: 0, text: "No Result" }] });
      } else {
        if (res.data.length > 0) {
          this.setState({ items: res.data });
        } else {
          this.setState({ items: [{ id: 0, text: "No Result" }] });
        }
      }
    });
  };
  render() {
    const items = this.state.items.map((item) => {
      if (item.id === 0) {
        return (
          <div
            className="dropdown-item align-drop"
            key={item.id}
            data-key={item.id}
            data-value={item.id}
          >
            {item.text}
          </div>
        );
      }
      let role = "";
      role = item.isAdmin ? 2 : role;
      role = item.isTrainee ? 4 : role;
      role = item.isTrainer ? 3 : role;
      return (
        <div
          className="dropdown-item align-drop"
          key={item.id}
          data-key={item.id}
          data-value={item.firstName}
          onClick={() =>
            this.handleSelectItem(item.id, item.firstName, item.lastName, role)
          }
        >
          {item.firstName + " " + item.lastName + " - " + item.email}
        </div>
      );
    });
    return (
      <React.Fragment>
        <div className="form-group">
          <div
            className="dropdown"
            role="button"
            id="userInfoDropdownLink"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            ref={this.setWrapperRef}
          >
            <input
              value={this.state.value}
              type="text"
              className={"form-control"}
              name={this.props.name}
              placeholder={this.props.title}
              autoComplete={this.props.autoComplete}
              onChange={(e) => {
                this.handleOnSearch(e.target.value);
              }}
              onFocus={this.handleClick}
            />
            {!this.state.id && (
              <i style={{ position: "absolute", top: "6px", right: "16px" }}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  fill="currentColor"
                  className="bi bi-search"
                  viewBox="0 0 16 16"
                >
                  <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                </svg>
              </i>
            )}
            {this.state.id && (
              <i
                onClick={() => {
                  this.handleClearItem(this.props.name);
                }}
                style={{ position: "absolute", top: "6px", right: "16px" }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-x"
                  viewBox="0 0 16 16"
                >
                  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                </svg>
              </i>
            )}
            {this.state.menuOpen && (
              <div
                className="dropdown-menu show dropdown_menu_show"
                aria-labelledby="userInfoDropdownLink"
              >
                {items}
              </div>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default SearchInput;
