import http from './httpService';
import apiEndpoint from '../config';

// http.setAuthToken(getAuthToken);

export function login(reqObj) {
    try {
        return http.post(apiEndpoint.auth.login, reqObj).then((response) => {
            if (response.status === 200) {
                return response;
            }
        });
    } catch (error) {
        console.error(error)
        return error;
    }
}

export function getAuthToken() {
    return localStorage.getItem('_token_');
}


export default {
    login,
    getAuthToken
}