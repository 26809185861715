import React from "react";

export default function SubmitButton({isLoading, submitBtnText, backBtnText,  handleRegister, registerBtn=false}) {
  return (
    <>
      {isLoading ? (
        <div className="btn btn-lg btn-block text-uppercase">Loading...</div>
      ) : (
        <button type="submit" className="btn btn-lg btn-block text-uppercase">
          {submitBtnText}
        </button>
      )}

      <button
        className="reg-btn btn-lg btn-block text-uppercase"
        onClick={handleRegister}
      >
        {backBtnText}
      </button>
    </>
  );
}
