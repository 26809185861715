import  { useState } from "react";
import { calculateDuration } from "../utils/calculateDuration";
import config, { workOutCreate } from "../config";
import { validationSchema } from "../utils/validationSchema";
import { addOrDeleteActivity, updateActivity, handleMultidropdowmData, clearErrors } from "../utils/formHelpers"

export default function useWorkoutForm() {
  const [duration, setDuration] = useState(0);
  const [rounds, setRounds] = useState(config.workoutInitial);
  const [roundsError, setRoundsError] = useState(config.workoutErrorInitial);

  const setWorkoutDetails = (data) => {
    setRounds(data);
  };

  const setWorkoutErrorDetails = (data) => {
    setRoundsError(data);
  };

  const clearDropDownData = (roundsValue) => {
    let rounds = [...roundsValue];
    let round;
    rounds.map((roundData, index) => {
      round = { ...rounds[index] };
      round = resetRoundDetails(round, roundData.workoutTypeId, round.duration);
      rounds[index] = round;
      return null;
    });
    const errRes = clearErrors(roundsError)
    setRounds(rounds);
    setRoundsError(errRes)
  };

  const resetRoundDetails = (round, workoutType, duration) => {
      round.combos = {};
      round.moves = {};
      round.strikes = {};
      round.activity_info = [];
      return round;
  };

  const updateRounds = (index, name, value, fn=null, resetValues = false, reCalculate = false) => {
    const roundsRef = [...rounds];
    let round = { ...rounds[index] };
    if(name !== "combos" && name !== "moves" && name !== "strikes"){
      round[name] = value
    }
    if(fn){
      const fnRes = fn(value, round, name);
      round = {...round, ...fnRes}
    }
    if (resetValues) {
      round = resetRoundDetails(round, round.workoutTypeId, round.duration);
    }
    roundsRef[index] = round;
    if (reCalculate) {
      const totalDuration = calculateDuration(roundsRef);
      setDuration(totalDuration);
    }
    setRounds(roundsRef);
  };

  const updateRoundsError = (index, name, error, resetActivityErr = false) => {
    const errRes = [...roundsError].map((data, i) => {
      if(index ===i){
        return {
          ...data,
          [name]: error,
          activityError: resetActivityErr ? [] : data.activityError
        }
      }
      return data
    })
    if(name === "roundDifficultyError") errRes[index].durationError = ""
    setRoundsError(errRes);
  };

  const handleDeleteRoundClick = (index) => {
    if(rounds?.length <= 1) return;
    const roundsCopy = rounds.filter((_, i) => i !== index);
    setRounds(roundsCopy);
    const totalDuration = calculateDuration(roundsCopy);
    setDuration(totalDuration);
  
    let roundsErrorCopy = roundsError.filter((_, i) => i !== index);;
    setRoundsError(roundsErrorCopy);
  };

  const handleAddRoundClick = () => {
    let index = Math.floor(Math.random() * Date.now());
    let roundValues = workOutCreate(index, rounds?.length + 1);
    let roundsErrorSet = {...config.roundError};
    setRounds((prev) => [...prev, roundValues]);
    setRoundsError((prev) => [...prev, roundsErrorSet]);
  };

  const handleItemCommonSelected = (id, value, name, i, roundId, selectedValues) => {
    updateRounds(roundId, name, id, handleMultidropdowmData);
    updateRoundsError(roundId, `${name}Error`, "");
  };

  const handleAddActivity = (e, roundId,) => {
    const roundsRes = addOrDeleteActivity(rounds, "activity_info", roundId, { activityListId: 0, durationSec: 0 })
    const roundsErrRes = addOrDeleteActivity(roundsError, "activityError", roundId,
    { durationError: false, activityError: false });
    roundsErrRes[roundId].workoutTypeError = "";

    setWorkoutDetails(roundsRes);
    setWorkoutErrorDetails(roundsErrRes);
  };

  const hanldeRemove = (e, roundId, i) => {
    const roundsRes = addOrDeleteActivity(rounds, "activity_info", roundId, null, i, true);
    const roundsErrRes = addOrDeleteActivity(roundsError, "activityError", roundId, null, i, true);
    if (roundsRes[roundId]?.activity_info?.length === 0) {
      roundsErrRes[roundId].workoutTypeError = "Please add activity";
    }

    setWorkoutDetails(roundsRes);
    setWorkoutErrorDetails(roundsErrRes);
  };

  const handleActivityDuration = (e, roundIndex, activityIndex, name) => {
    const activityRes = updateActivity(rounds, roundIndex, activityIndex, "activity_info", "durationSec", e.target.value);
    setRounds(activityRes);
    const error = validationSchema("durationSec", e.target.value);
    const activityErrRes = updateActivity(roundsError, roundIndex, activityIndex, "activityError", "durationError", error);
    setRoundsError(activityErrRes);
  };

  return [
    rounds,
    roundsError,
    duration,
    setDuration,
    setWorkoutDetails,
    setWorkoutErrorDetails,
    handleAddRoundClick,
    handleDeleteRoundClick,
    updateRounds,
    updateRoundsError,
    clearDropDownData,
    handleActivityDuration,
    handleItemCommonSelected,
    handleAddActivity,
    hanldeRemove
  ];
}
