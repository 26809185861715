import React from "react";

export default function colDetails({ label, value }) {
  return (
    <div className="col-sm-4">
      <div className="each-detail">
        <label className="workout-details-page">{label}</label>
        <div>
          <label>{value}</label>
        </div>
      </div>
    </div>
  );
}
