export const getStartingDate = (date) => {
  return (
    new Date(date - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .slice(0, -1) +
    " " +
    gettimezone()
  ).replace("T", " ");
};

const gettimezone = () => {
  let offset = new Date().getTimezoneOffset(),
    o = Math.abs(offset);
  return (
    (offset < 0 ? "+" : "-") +
    ("00" + Math.floor(o / 60)).slice(-2) +
    ":" +
    ("00" + (o % 60)).slice(-2)
  );
};
