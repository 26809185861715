import React from "react";
import Loader from "../../common/loader";

export default function formComponent(props) {
  return (
    <div className="login-body">
      {props.isLoading && <Loader />}
      <form onSubmit={props.handleSubmit}>
        <div className="card-signin">
          <div className={props.formClassNames}>
            <img
              alt="logo"
              src="https://472d8k2rdaaa1pstff391x82-wpengine.netdna-ssl.com/wp-content/uploads/2018/11/cropped-white-logo-newest.png"
              height="30"
            />
          </div>
          <div className="api-error">
            <span className="error-message">{props.pageErrors}</span>
          </div>
          {props.children}
        </div>
      </form>
    </div>
  );
}
