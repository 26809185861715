import { useState, useCallback } from "react";
import {
  strikesListApi,
  combosListApi,
  movesListApi,
  activityListApi,
} from "../services/userService";

export default function useCallApi(levelId, padVal, history) {
  const [combos, setCombos] = useState({});
  const [activites, setActivites] = useState({});
  const [moves, setMoves] = useState([]);
  const [strikes, setStrikes] = useState([]);
  const [pageErrors, setPageErros] = useState("");
  const [isLoadingList, setIsLoadingList] = useState(false);

  const updateDropDownDetails = useCallback(() => {
    let promiseList = [
      combosListApi(levelId, padVal, false),
      combosListApi(levelId, padVal, true),
      activityListApi(levelId, padVal, 1),
      activityListApi(levelId, padVal, 2),
      activityListApi(levelId, padVal, 5),
    ];
    const listOrder = [1, 2, "Cardio", "Strike", "Core"];
    let activities = {};
    let combos = {};
    setIsLoadingList(true);
    Promise.all(promiseList)
      .then((values) => {
        setIsLoadingList(false);
        values.map((data, index) => {
          if (index === 0 || index === 1) {
            combos[listOrder[index]] = data && data.data ? data.data : null;
          } else {
            activities[listOrder[index]] = data && data.data ? data.data : null;
          }
          return true;
        });
        setCombos(combos);
        setActivites(activities);
      })
      .catch(() => {
        setIsLoadingList(false);
      });

    setIsLoadingList(true);
    strikesListApi(levelId, padVal)
      .then((res) => {
        setIsLoadingList(false);
        setStrikes(res && res.data ? JSON.parse(JSON.stringify(res.data)) : []);
      })
      .catch((err) => {
        setIsLoadingList(false);
        console.log(err);
      });
    setIsLoadingList(true);
    movesListApi(levelId)
      .then((res) => {
        setIsLoadingList(false);
        setMoves(res && res.data ? JSON.parse(JSON.stringify(res.data)) : []);
      })
      .catch((err) => {
        setIsLoadingList(false);
        console.log(err);
      });
  }, [levelId, padVal]);

  const callWorkoutApis = (apiName, requestBody) => {
    setIsLoadingList(true);
    apiName(requestBody)
      .then((res) => {
        setIsLoadingList(false);
        if (res && res.code === 200) {
          history.push("/workout");
        } else {
          setPageErros(res.message);
          window.scrollTo(0, 0);
        }
      })
      .catch((err) => {
        setIsLoadingList(false);
      });
  };
  return [
    combos,
    activites,
    strikes,
    moves,
    pageErrors,
    isLoadingList,
    updateDropDownDetails,
    callWorkoutApis,
  ];
}
