import React from "react";
import { Input } from "../../common/input";
import ErrorMessage from "./errorMessage";

export default function EmailField({
  name,
  username,
  password,
  confirmPassword,
  confirmPasswordError,
  usernameError,
  passwordError,
  handleChange,
  registerAutoComplete = true,
}) {
  return (
    <>
      <div className="form-label-group">
        <label htmlFor="loginEmail">Email</label>
        <div className="form-input-msg">
          <Input
            name={name}
            type="email"
            className="form-control"
            placeHolder="Enter your Email ID"
            value={username}
            onChange={handleChange}
            autoComplete={registerAutoComplete}
          />
          <ErrorMessage errorMsg={usernameError} />
        </div>
      </div>
      <div className={`form-label-group`}>
        <label htmlFor="loginPassword">Password</label>
        <div className="form-input-msg">
          <Input
            name="password"
            type="password"
            className="form-control"
            placeHolder="Enter your Password"
            value={password}
            onChange={handleChange}
            autoComplete={registerAutoComplete}
          />
          <ErrorMessage errorMsg={passwordError} />
        </div>
      </div>
      {!registerAutoComplete && (
        <div className="form-label-group">
          <label htmlFor="loginPassword">Confirm Password</label>
          <div className="form-input-msg">
            <Input
              name="confirmPassword"
              type="password"
              className="form-control"
              placeHolder="Please Re-Type Password"
              value={confirmPassword}
              onChange={handleChange}
              autoComplete="off"
            />
            <ErrorMessage errorMsg={confirmPasswordError} />
          </div>
        </div>
      )}
    </>
  );
}
