import { Component } from 'react';

class Logout extends Component {

    componentDidMount() {
        localStorage.removeItem('_token_');
        window.location = "/login";
    }

    render() {
        return null;
    }
}

export default Logout;