import React, { useState ,useEffect}  from 'react';


function LeaderboardItem({i,data,trainerStrikes}) {

    const [myPower, setMyPower] = useState([]);

    useEffect(()=>{
        console.log(`new punch ${data.power}`)

        setMyPower( arr => [...arr, data.power]);
        
        // for(let i =0; i < myPower.length;i++)
        // {
        //     console.log(`Punch index ${i} ->${myPower[i]} out of ${myPower.length-1}`)
        // }
        
    },[data.power])


    const average = (array) => array.reduce((a, b) => a + b,) / array.length;

    return (
        <>
            <div className="leaderboard-rank">#{i + 1}</div>
			<div className="leaderboard-name">{data.name}</div>
            <div className="leaderboard-power">{myPower.length > 0 ? <>{Math.round(average(myPower))}</> : <>0</>}%</div>
			<div className="leaderboard-pace">{data.strike} / {trainerStrikes}</div>
        </>
    );
}

export default LeaderboardItem;