import React from "react";
import Form from "../../common/form";
import config from "../../config";
import {
  workoutDetails,
  strikesListApi,
  activityListApi,
} from "../../services/userService";
import getSocket from "../../services/socketService";
import { withRouter } from "react-router-dom";
import { deleteWorkout } from "../../services/userService";
import { convertDateAddOffset } from "../../utils/convertTimeZone"
import "./dashboardPage.css";
import LiveDashboard from "./liveDashboard";

class DashboardPage extends Form {
  constructor() {
    super();
    this.state = {
      response: 0,
      workoutsDetails: [],
      endpoint: process.env.REACT_APP_SOCKET_URL,
      leaderBoard: [],
      strikesList: {},
      activityList: {},
      workoutSummary: "",
      workoutId: "",
      ws: null,
      retryAttempts: 0,
      difficultyLevelId: 1,
      isLoading: false,
      roundId: -1,
      roundTypeId: "",
      prev: 0,
      startTime: "",
      endTime: "",
      trainerStrikes: 1,
      socketConnectionAttempts: 0,
      sortName: "pace",
      sortDirection: "dsc",
      channelName: "",
      uid: 0
    };
  }

  timeout = 500;

  componentDidMount() {
    const workoutId = parseInt(
      this.props && this.props.match.params
        ? this.props.match.params.workoutId
        : 0
    );
    this.setState({ workoutId: parseInt(workoutId) });
    this.connect(workoutId);
    this.setState({ isLoading: true });
    workoutDetails(workoutId)
      .then((res) => {
        this.setState({ isLoading: false });
        if (res && res.data && res.data !== null) {
          let response = res.data;
          if (
            new Date() >=
            convertDateAddOffset(new Date(response?.startsAt)) &&
            new Date() <= convertDateAddOffset(new Date(response?.endsAt))
          ) {
            let strikes = {};
            strikesListApi(response.levelId, response.padTypeId)
              .then((res) => {
                if (res && res.data) {
                  res.data.map((data) => (strikes[data.id] = data));
                  this.setState({ strikesList: strikes });
                }
              })
              .catch((err) => console.log(err));

            let activities = {};
            Promise.all([1, 2, 5].map(async workoutTypeId => {
              const res = await activityListApi(response.levelId, response.padTypeId, workoutTypeId);
              for (let index = 0; index < res.data?.length; index++) {
                const name = config.workoutTypeIdObj[workoutTypeId];
                const resName = res.data[index].id;
                if (!activities[name]) activities[name] = {};
                activities[name][resName] = res.data[index];
              }
            })).then(() => {
              this.setState({
                activityList: activities,
              });
            });
            const startTime = convertDateAddOffset(
              new Date(response?.startsAt)
            );
            const endTime = convertDateAddOffset(
              new Date(response?.endsAt)
            );
            this.setState(
              {
                workoutsDetails: res && res.data ? res.data : [],
                startTime: startTime,
                endTime: endTime,
              },
              () => this.updateRounds()
            );
          } else {
            this.props.history.push(`/workout/details/${workoutId}`);
          }
        }
      })
      .catch((err) => console.error(err));
  }

  updateRounds = () => {
    if (
      !this.state.isLoading &&
      this.state.workoutsDetails &&
      this.state.workoutsDetails?.rounds?.length > 0
    ) {
      const { workoutsDetails, startTime } = this.state;

      let roundId = 0;
      let prevDuration = 0;
      let roundTypeId = "warmUpTime";
      let timeSpent = Math.floor(
        (new Date().getTime() - new Date(startTime).getTime()) / 1000
      );

      let actualDuration =
        workoutsDetails?.rounds[parseInt(roundId)]?.duration * 60 +
        workoutsDetails?.rounds[parseInt(roundId)]?.coolDownTime +
        workoutsDetails?.rounds[parseInt(roundId)]?.warmUpTime;
      let prev = 0;

      while (timeSpent >= actualDuration) {
        if (new Date(startTime) <= new Date()) {
          timeSpent = Math.floor(
            (new Date().getTime() - new Date(startTime).getTime()) / 1000
          );

          if (timeSpent >= actualDuration) {
            if (
              workoutsDetails.rounds &&
              workoutsDetails?.rounds?.length > 0 &&
              roundId < workoutsDetails?.rounds?.length - 1
            ) {
              const tempRound = roundId;
              prev +=
                workoutsDetails?.rounds[parseInt(tempRound)]?.duration * 60 +
                workoutsDetails?.rounds[parseInt(tempRound)]?.coolDownTime +
                workoutsDetails?.rounds[parseInt(tempRound)]?.warmUpTime;
              roundId += 1;
            }
            prevDuration =
              workoutsDetails?.rounds[parseInt(roundId)]?.duration * 60 +
              workoutsDetails?.rounds[parseInt(roundId)]?.coolDownTime +
              workoutsDetails?.rounds[parseInt(roundId)]?.warmUpTime;
          }
        }
        actualDuration += prevDuration;
      }
      if (
        timeSpent - prev <=
        workoutsDetails?.rounds[parseInt(roundId)]?.warmUpTime
      ) {
        roundTypeId = "warmUpTime";
      } else if (
        timeSpent - prev >
        workoutsDetails?.rounds[parseInt(roundId)]?.warmUpTime &&
        timeSpent - prev <=
        workoutsDetails?.rounds[parseInt(roundId)]?.warmUpTime +
        workoutsDetails?.rounds[parseInt(roundId)]?.duration * 60
      ) {
        roundTypeId = "duration";
      } else {
        roundTypeId = "coolDownTime";
      }
      this.setState({ roundId: roundId, prev: prev, roundTypeId: roundTypeId });
    }
  };

  componentWillUnmount() {
    const { ws } = this.state;
    if (ws && ws.readyState !== WebSocket.CLOSED) {
      ws.close();
    }
  }

  connect = (workoutId) => {
    let ws = getSocket(workoutId);
    this.setState({
      ws: ws,
    });

    ws.onopen = () => {
			console.log("connected websocket main component");
			ws.send('{"event": 1}');
			console.log("Socket Open");
      this.setState({
        retryAttempts: 0
      });
		};

		ws.onclose = (e) => {
			console.log("Socket is closed", e.reason);
			if (this.state.retryAttempts < 3) {
        this.setState({
          retryAttempts: this.state.retryAttempts + 1
        });
				this.connect(workoutId);
			} else {
        console.log("No attempt will be remade to open");
      }
		}

		ws.onerror = (err) => {
			console.error(
				"Socket encountered error: ",
				err.message
			);
		};

    ws.onmessage = (event) => {
      //Message Listener
      console.log("Got message: " + event.data);
      let responseDatas = JSON.parse(event.data);

      if (responseDatas && responseDatas.event === 15) {
        this.setState({
          channelName: responseDatas.data.channel_name,
          uid: responseDatas.data.uid
        })
      }

      if (responseDatas && responseDatas.event === 5) {
        let leader = [];

        if (
          responseDatas.data &&
          responseDatas.data.leaders !== null &&
          responseDatas.data.leaders.length > 0
        ) {
          const leaderboardValue = responseDatas.data.leaders;
          let updatedLeaderBoard;
          let objName = this.state.sortName === "pace" ? "strike" : "power";
          if (objName === "strike" && this.state.sortDirection === "dsc") {
            updatedLeaderBoard = responseDatas.data.leaders;
          } else {
            if (this.state.sortDirection === "asc") {
              updatedLeaderBoard = leaderboardValue.sort(
                (a, b) => a[objName] - b[objName]
              );
            } else {
              updatedLeaderBoard = leaderboardValue.sort(
                (a, b) => b[objName] - a[objName]
              );
            }
          }
          leader = [...updatedLeaderBoard];
        }

        this.setState({
          leaderBoard: leader,
          trainerStrikes:
            responseDatas.data &&
              responseDatas.data.trainer !== null &&
              responseDatas.data.trainer.strike
              ? responseDatas.data.trainer.strike
              : 0,
        });
      }
    };
  };

  handleSortPower = (name, direction) => {
    let temp = direction;
    if (name !== this.state.sortName) {
      temp = "dsc";
    }

    const { leaderBoard } = this.state;
    if (leaderBoard && leaderBoard?.length > 0) {
      let updatedLeaderBoard;
      let objName = name === "pace" ? "strike" : "power";
      let trainerInfo = [];
      trainerInfo.push(leaderBoard.shift());
      if (temp === "asc") {
        updatedLeaderBoard = leaderBoard.sort(
          (a, b) => a[objName] - b[objName]
        );
      } else {
        updatedLeaderBoard = leaderBoard.sort(
          (a, b) => b[objName] - a[objName]
        );
      }
      updatedLeaderBoard = [...trainerInfo, ...updatedLeaderBoard];
      this.setState({
        sortName: name,
        sortDirection: temp,
        leaderBoard: updatedLeaderBoard,
      });
    } else {
      this.setState({
        sortName: name,
        sortDirection: temp
      });
    }
  };

  check = () => {
    const { ws } = this.state;
    if (!ws || ws.readyState === WebSocket.CLOSED) {
      console.log(this.state);
      console.log("exising connection closed");
      //this.connect(this.state.workoutId); //check if websocket instance is closed, if so call `connect` function.
    }
  };
  handleDeleteWorkout = (id) => {
    const requestBody = {
      id: id,
    };
    deleteWorkout(requestBody).then((res) => {
      console.log(res);
      if (res && res.code !== 200) {
        this.setState({
          pageErrors: res.message,
        });
        window.scrollTo(0, 0);
      } else {
        this.props.history.push("/workout");
      }
    });
  };

  render() {
    const {
      workoutsDetails,
      startTime,
      leaderBoard,
      trainerStrikes,
      roundTypeId,
      sortName,
      sortDirection,
      activityList,
      channelName,
      uid
    } = this.state;
    return (
      <div>
        <LiveDashboard
          workoutsDetails={workoutsDetails}
          startTime={startTime}
          leaderBoard={leaderBoard}
          trainerStrikes={trainerStrikes}
          roundTypeId={roundTypeId}
          sortName={sortName}
          sortDirection={sortDirection}
          handleSortPower={this.handleSortPower}
          activityList={activityList}
          channelName={channelName}
          uid={uid}
        />
      </div>
    );
  }
}

export default withRouter(DashboardPage);
