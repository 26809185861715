import { useState } from "react";
import {
  checkForEmptyString,
  validCharactersRegex,
  validEmailRegex,
} from "../utils/utils";

export default function useLoginForm() {
  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    password: "",
    email: "",
    confirmPassword: "",
    playFabId: "",
  });

  const [errors, setErrors] = useState({
    firstNameError: "",
    lastNameError: "",
    passwordError: "",
    emailError: "",
    confirmPasswordError: "",
    isFormValid: true,
  });

  const checkCharOnly = (value, name) => {
    const tempValue = value.trim();
    let errValue = "";
    if (checkForEmptyString.test(tempValue)) {
      errValue = !validCharactersRegex.test(tempValue)
        ? "Characters only!"
        : "";
    } else {
      errValue = `Please Enter ${name} Name`;
    }
    return errValue;
  };

  const handleValidations = (event) => {
    const { name, value } = event.target;
    let tempObj = { ...errors };

    switch (name) {
      case "email":
        if (checkForEmptyString.test(value.trim())) {
          tempObj.emailError =
            value.trim() !== "" && !validEmailRegex.test(value)
              ? "Email is not valid!"
              : "";
        } else {
          tempObj.emailError = "Please Enter Email ID";
        }
        break;
      case "password":
        tempObj.passwordError =
          value.trim() === "" || value.length < 6
            ? "Password must be 6 characters long!"
            : "";
        if (value === data.confirmPassword) {
          tempObj.confirmPasswordError = "";
        } else if (data.confirmPassword) {
          tempObj.confirmPasswordError = "Password doesnot match";
        }
        break;
      case "confirmPassword":
        if (value !== data.password) {
          tempObj.confirmPasswordError = "Password doesnot match";
        } else {
          tempObj.confirmPasswordError = "";
        }
        break;
      case "firstName":
        tempObj.firstNameError = checkCharOnly(value, "First");
        break;
      case "lastName":
        tempObj.lastNameError = checkCharOnly(value, "Last");
        break;
      default:
        break;
    }
    setData((prev) => ({
      ...prev,
      [name]: value.trim(),
    }));
    const err = `${name}Error`;
    setErrors((prev) => ({
      ...prev,
      [`${name}Error`]: tempObj[err],
      confirmPasswordError: tempObj.confirmPasswordError,
    }));
  };

  const handleChange = (event) => {
    event.preventDefault();
    handleValidations(event);
  };

  const handleSubmitValidations = (
    passwordError,
    emailError,
    firstNameError="",
    lastNameError="",
    confirmPasswordError=""
  ) => {
    setErrors((prev) => ({
      ...prev,
      passwordError,
      emailError,
      firstNameError,
      lastNameError,
      confirmPasswordError,
    }));
  };

  return [data, errors, handleChange, handleSubmitValidations];
}
