import http from "./httpService";
import apiEndpoint from "../config";

export async function workoutListApi() {
  try {
    let userToken = localStorage.getItem("_token_");
    let offset = new Date().getTimezoneOffset(),
      o = Math.abs(offset);
    let timezone =
      (offset < 0 ? "+" : "-") +
      ("00" + Math.floor(o / 60)).slice(-2) +
      ":" +
      ("00" + (o % 60)).slice(-2);
    console.log(timezone);
    return await http
      .post(
        apiEndpoint.workout.listWorkout,
        { timezone },
        { headers: { Authorization: `Bearer ${userToken}` } }
      )
      .then((response) => {
        if (response.status === 200) {
          return response.data;
        }
      });
  } catch (error) {
    localStorage.removeItem("_token_");
    window.location = "/login?expired=1";
    console.error(error);
    return null;
  }
}

export async function uploadPoster(image) {
  try {
    let userToken = localStorage.getItem("_token_");
    var Image = new FormData();
    Image.append("image", image);
    let uploadHeader = {
      headers: {
        Authorization: `Bearer ${userToken}`,
        "Content-type": "multipart/form-data",
        accept: "text/plain",
      },
    };
    return await http
      .post(apiEndpoint.workout.uploadPoster, Image, uploadHeader)
      .then((response) => {
        if (response.status === 200) {
          console.log(response);
          return response.data;
        }
      });
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function updatePoster(posterUrl, workoutId) {
  try {
    let userToken = localStorage.getItem("_token_");
    return await http
      .post(
        apiEndpoint.workout.updatePoster,
        { posterUrl, workoutId },
        { headers: { Authorization: `Bearer ${userToken}` } }
      )
      .then((response) => {
        if (response.status === 200) {
          return response.data;
        }
      });
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function strikesListApi(workoutLevelID, PadType = 1) {
  try {
    let userToken = localStorage.getItem("_token_");
    return await http
      .post(
        apiEndpoint.workout.strikesList,
        { workoutLevelID, PadType },
        { headers: { Authorization: `Bearer ${userToken}` } }
      )
      .then((response) => {
        if (response.status === 200) {
          return response.data;
        }
      });
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function activityListApi(
  workoutLevelID,
  PadType = 1,
  workoutTypeID
) {
  try {
    let userToken = localStorage.getItem("_token_");
    return await http
      .post(
        apiEndpoint.workout.activityList,
        { workoutLevelID, PadType, workoutTypeID },
        { headers: { Authorization: `Bearer ${userToken}` } }
      )
      .then((response) => {
        if (response.status === 200) {
          return response.data;
        }
      });
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function createWorkout(paramList) {
  try {
    let userToken = localStorage.getItem("_token_");
    return await http
      .post(apiEndpoint.workout.createWorkout, paramList, {
        headers: { Authorization: `Bearer ${userToken}` },
      })
      .then((response) => {
        if (response.status === 200) {
          return response.data;
        }
      });
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function saveToDraft(paramList) {
  try {
    let userToken = localStorage.getItem("_token_");
    return await http
      .post(apiEndpoint.workout.saveToDraft, paramList, {
        headers: { Authorization: `Bearer ${userToken}` },
      })
      .then((response) => {
        if (response.status === 200) {
          return response.data;
        }
      });
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function createTrainer(paramList) {
  try {
    let userToken = localStorage.getItem("_token_");
    return await http
      .post(apiEndpoint.workout.createTrainer, paramList, {
        headers: { Authorization: `Bearer ${userToken}` },
      })
      .then((response) => {
        if (response.status === 200) {
          return response.data;
        }
      });
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function UpdatePermissions(paramList) {
  try {
    let userToken = localStorage.getItem("_token_");
    return await http
      .post(apiEndpoint.workout.updatePermissions, paramList, {
        headers: { Authorization: `Bearer ${userToken}` },
      })
      .then((response) => {
        if (response.status === 200) {
          return response.data;
        }
      });
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function searchUser(paramList) {
  try {
    let userToken = localStorage.getItem("_token_");
    return await http
      .post(apiEndpoint.workout.searchUser, paramList, {
        headers: { Authorization: `Bearer ${userToken}` },
      })
      .then((response) => {
        if (response.status === 200) {
          return response.data;
        }
      });
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function deleteWorkout(paramList) {
  try {
    let userToken = localStorage.getItem("_token_");
    let config = {
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      data: {
        id: paramList.id,
      },
    };
    return await http
      .delete(apiEndpoint.workout.deleteWorkout, config)
      .then((response) => {
        if (response.status === 200) {
          return response.data;
        }
      });
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function workoutDetails(id) {
  const UNAUTHORIZED = 401;
  try {
    let userToken = localStorage.getItem("_token_");
    return await http
      .post(
        apiEndpoint.workout.workoutDetails,
        { id },
        { headers: { Authorization: `Bearer ${userToken}` } }
      )
      .then((response) => {
        if (response.status === 200) {
          return response.data;
        }
      });
  } catch (error) {
    const { status } = error.response;
    if (status === UNAUTHORIZED) {
      //refreshToken();
      localStorage.removeItem("_token_");
      window.location = "/login?expired=1";
      console.error(error);
      return null;
    }
    return null;
  }
}

export async function combosListApi(workoutLevelID, padType, isDefensiveMove) {
  try {
    let userToken = localStorage.getItem("_token_");
    return await http
      .post(
        apiEndpoint.workout.combosList,
        { workoutLevelID, padType, isDefensiveMove },
        { headers: { Authorization: `Bearer ${userToken}` } }
      )
      .then((response) => {
        if (response.status === 200) {
          return response.data;
        }
      });
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function movesListApi(workoutLevelID) {
  try {
    let userToken = localStorage.getItem("_token_");
    return await http
      .post(
        apiEndpoint.workout.moves_list,
        { workoutLevelID },
        { headers: { Authorization: `Bearer ${userToken}` } }
      )
      .then((response) => {
        if (response.status === 200) {
          return response.data;
        }
      });
  } catch (error) {
    console.error(error);
    return null;
  }
}

// eslint-disable-next-line
async function refreshToken() {
  let uploadHeader = {
    headers: {
      accept: "text/plain",
    },
  };
  try {
    let token = localStorage.getItem("_token_");
    return await http
      .post(apiEndpoint.workout.refreshToken, { token }, uploadHeader)
      .then((response) => {
        if (response.status === 200) {
          localStorage.setItem("_token_", response.data);
        }
      });
  } catch (error) {
    localStorage.removeItem("_token_");
    window.location = "/login";
    console.error(error);
    return null;
  }
}

export default {
  workoutListApi,
  uploadPoster,
  strikesListApi,
  workoutDetails,
  createWorkout,
};
