import React, { useState, useEffect } from "react";
import { secondsToMMSS } from "../../utils/secondsToMMSS";

export function Header({ workoutsDetails, timer, currentRound, roundSchedule }) {
	const [time, setTime] = useState(new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }));

	useEffect(() => {
		let secTimer = setInterval(() => {
			setTime(new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }))
		}, 1000)
		return () => clearInterval(secTimer);
	}, []);

	if (!workoutsDetails || !workoutsDetails.rounds || !roundSchedule || !currentRound) return null;

	const roundBlocks = roundSchedule.map((round, i) => {
		let status;
		if (timer > round.endTime) {
			status = "past";
		} else if (round === currentRound) {
			status = "current";
		} else {
			status = "future";
		}

		return (
			<div className={"round-block " + status} key={i}>
				<div className="progress-indicator-outer">
					{currentRound === round && <div className="progress-indicator-inner" style={{ width: ((timer - (round.endTime - round.duration)) / round.duration) * 100 + '%' }}><div className="progress-indicator-mark"></div></div>}
				</div>
				<div className={"name " + status}>{round.name}</div>
			</div>
		);
	});

	return (
		<div className="header">
			<div className="workout-title-wrapper">
				<span>{workoutsDetails.trainerDetails.name}: Live Workout</span>
				<span className="current-time">{time}</span>
			</div>
			<div className="timing-wrapper">
				<div className="timing-detail">
					<div className="time-value">{secondsToMMSS(timer)}</div>
					<div className="time-desc">Time Elapsed</div>
				</div>
				<div className="round-blocks-wrapper">
					{roundBlocks}
				</div>
				<div className="timing-detail">
					<div className="time-value">{secondsToMMSS(roundSchedule[roundSchedule.length - 1].endTime - timer)}</div>
					<div className="time-desc">Time Remaining</div>
				</div>
			</div>
		</div>
	);
}