import React from "react";

export default function workoutCreate(props) {
  return (
    <div className="row">
      <div className="col-sm-10 col-md-10 m-auto workout-container">
        <div className="logo"></div>
        <div className="row">{props.children}</div>
      </div>
    </div>
  );
}
