import React from "react";
import { Input } from "../../common/input";

export default function formRadioButtons({
  activeValue,
  name,
  checkedValue,
  placeholder,
  value,
  isNotChecked = false,
  hideInput
}) {
  return (
    <>
      <label
        className={`btn btn-primary ${
          activeValue === checkedValue ? " active" : ""
        }`}
      >
        {!hideInput && (
          <Input
            name={name}
            type="radio"
            placeholder={placeholder}
            checked={isNotChecked ? !checkedValue : checkedValue}
            value={`${activeValue}`}
            //   onChange={this.handleChangedefensive}
          />
        )}
        {value}
      </label>
    </>
  );
}
