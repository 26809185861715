import React from 'react'
import { Input } from "../../common/input";
import ErrorMessage from "../LoginForm/errorMessage";

export default function NameField({firstName,lastName,firstNameError,lastNameError,handleChange}) {
    return (
        <div className="row">
        <div className="form-label-group col">
          <label htmlFor="loginEmail">First Name</label>
          <div className="form-input-msg">
            <Input
              name="firstName"
              type="text"
              className="form-control"
              placeHolder="First Name"
              value={firstName}
              onChange={handleChange}
              autoComplete="off"
            />
            <ErrorMessage errorMsg={firstNameError} />
          </div>
        </div>
        <div className="form-label-group col">
          <label htmlFor="loginEmail">Last Name</label>
          <div className="form-input-msg">
            <Input
              name="lastName"
              type="text"
              className="form-control"
              placeHolder="Last Name"
              value={lastName}
              onChange={handleChange}
              autoComplete="off"
            />
            <ErrorMessage errorMsg={lastNameError} />
          </div>
        </div>
      </div>
    )
}
