import React from "react";
import ColDetails from "./colDetails";

export default function strikesDetails({
  data,
  comboList,
  strikesList,
  movesList,
}) {
  const getWorkoutTypeValues = (type, valueList, value) => {
      const parsedValue = JSON.parse(type);
      const workoutType = value ? value : valueList;
      const workoutValues =
        type && !isEmpty(valueList) && parsedValue?.length > 0
          ? parsedValue.map((data, index) => (
              <div key={index}>{workoutType && workoutType[data]?.name}</div>
            ))
          : "-";
      return workoutValues;
  };

  const isEmpty = (obj) => {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  };

  return (
    <div className="row activity-list-border">
      <ColDetails
        label="Combos"
        value={getWorkoutTypeValues(
          data.combos,
          comboList,
          comboList[data.isDefensive ? 1 : 2]
        )}
      />
      <ColDetails
        label="Strikes"
        value={getWorkoutTypeValues(data.strikes, strikesList)}
      />

      <ColDetails
        label="Strikes"
        value={getWorkoutTypeValues(data.moves, movesList)}
      />

      <div className="col-sm-4">
        <div className="each-detail">
          <label className="workout-details-page">Defensive Moves</label>
          <div className="btn-group-toggle radio-buttons" data-toggle="buttons">
            <label
              className={`btn disable-radio-btns defensiveMoves ${
                data.isDefensive === true ? " active" : ""
              }`}
            >
              Yes
            </label>
            <label
              className={`btn disable-radio-btns defensiveMoves ${
                data.isDefensive === false ? " active" : ""
              }`}
            >
              No
            </label>
          </div>
        </div>
      </div>
    </div>
  );
}
