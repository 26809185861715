import { Component } from "react";

class Form extends Component {
    state = {
        data: {}
    };

    handleChange = (e) => {
        const data = { ...this.state.data };
        data[e.currentTarget.name] = e.currentTarget.value;
        this.setState({ data });
    }

    handleSubmitLogin = (e) => {
        e.preventDefault();
        this.doSubmit();
    }

}

export default Form;
