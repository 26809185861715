import React, { Component } from "react";
import { Link } from "react-router-dom";
import SliderComponent from "../components/SliderComponent/sliderComponent";
import "../components/WorkoutComponent/workoutComponent.css";

import "../common/style.css";
import { workoutListApi } from "../services/userService";
import {convertDateAddOffset} from "../utils/convertTimeZone"
import ReactTooltip from "react-tooltip";
import Loader from "../common/loader";

class WorkoutComponent extends Component {
  state = {
    workoutsList: {
      completedList: [],
      todayList: [],
      upComingList: [],
      draftedList: [],
    },
    liveList: [],
    todayUpdatedList: [],
    isLoading: false
  };

  componentDidMount() {
    let emptyResponse = {
      completedList: [],
      todayList: [],
      upComingList: [],
      draftedList: [],
    };
    this.setState({isLoading: true})
    workoutListApi().then((res) => {
      this.setState({isLoading: false})
      if (res && res.data) {
        let liveList = []
        let todayUpdatedList = []
        res.data.todayList.map((data) => {
          if (
            new Date().getTime() >=
              convertDateAddOffset(new Date(data.startsAt)).getTime() &&
            new Date().getTime() <=
              convertDateAddOffset(new Date(data.startsAt)).getTime() +
                data.duration * 1000
          ) {
            liveList.push(data)
          } else {
            todayUpdatedList.push(data)
          }
          return true;
        });

        this.setState({todayUpdatedList: todayUpdatedList, liveList: liveList, isLoading: false})
        this.setState({
          workoutsList: res && res.data ? res.data : emptyResponse,
        });
      } else {
        this.handleLogOut();
      }
    })
    .catch((err) => {
      this.setState({isLoading: false})
    })
  }

  handleLogOut = () => {
    console.log("logout");
    // localStorage.removeItem('_token_');
    // this.props.history.push("/logout");
  };

  render() {
    const { completedList, upComingList, draftedList } =
      this.state.workoutsList;
    const { liveList,todayUpdatedList, isLoading } = this.state;
    return (
      <React.Fragment>
        {isLoading && <Loader />}
        <h2 className="page-heading">Dashboard</h2>
        <div className="slider-container mt-50">
          <h3>Live</h3>
          {liveList && liveList.length > 0 ? (
            <div className="left-align-slick">
              <SliderComponent
                title="Today List"
                itemList={liveList}
                type={3}
              />
            </div>
          ) : (
            <div className="alert-for-no-records">No Events</div>
          )}
        </div>

        <div className="slider-container mt-50">
          <h3>Today</h3>
          {todayUpdatedList && todayUpdatedList.length > 0 ? (
            <div className="left-align-slick">
              <SliderComponent
                title="Today List"
                itemList={todayUpdatedList}
                type={3}
              />
            </div>
          ) : (
            <div className="alert-for-no-records">No Events</div>
          )}
        </div>

        <div className="slider-container mt-50">
          <h3>Upcoming</h3>
          {upComingList && upComingList.length > 0 ? (
            <div className="left-align-slick">
              <SliderComponent
                title="Upcoming List"
                itemList={upComingList}
                type={1}
              />
            </div>
          ) : (
            <div className="alert-for-no-records">No Events</div>
          )}
        </div>

        <div className="slider-container mt-50">
          <h3>Completed</h3>
          {completedList && completedList.length > 0 ? (
            <div className="left-align-slick">
              <SliderComponent
                title="Completed List"
                itemList={completedList}
                type={1}
              />
            </div>
          ) : (
            <div className="alert-for-no-records">No Events</div>
          )}
        </div>

        <div className="slider-container mt-50 mb-50">
          <h3>Draft</h3>
          {draftedList && draftedList.length > 0 ? (
            <div className="left-align-slick">
              <SliderComponent
                title="Draft List"
                itemList={draftedList}
                type={2}
              />
            </div>
          ) : (
            <div className="alert-for-no-records">No Events</div>
          )}
        </div>

        <Link
          type="button"
          to="/workout/create"
          className="btn btn-primary bmd-btn-fab floating-btn"
          data-tip
          data-for="registerTip"
          data-place="left"
        >
          <svg
            className="bi bi-plus"
            width="2em"
            height="2em"
            viewBox="2 0 16 16"
            fill="white"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M8 3.5a.5.5 0 01.5.5v4a.5.5 0 01-.5.5H4a.5.5 0 010-1h3.5V4a.5.5 0 01.5-.5z"
              clipRule="evenodd"
            />
            <path
              fillRule="evenodd"
              d="M7.5 8a.5.5 0 01.5-.5h4a.5.5 0 010 1H8.5V12a.5.5 0 01-1 0V8z"
              clipRule="evenodd"
            />
          </svg>
        </Link>
        <ReactTooltip id="registerTip" place="top" effect="solid">
          Create Workout
        </ReactTooltip>
      </React.Fragment>
    );
  }
}

export default WorkoutComponent;
