export const parseStringToObject = (item, strikeName, isDefensive = false) => {
  let strikeItems;
  let strikes = {};
  // convert string to array
  const parsedItem = JSON.parse(item);
  strikeItems =
    strikeName === "moves"
      ? parsedItem?.length > 0 && isDefensive
      : parsedItem?.length > 0;
  //convert array to object
  const resObj = strikeItems
    ? parsedItem?.reduce((_, item) => {
        strikes[item] = parseInt(item);
        return strikes;
      }, item)
    : {};
  return resObj;
};
