import React from "react";

export default function submitForm(props) {
  return (
    <>
      {!props.id && (
        <div className="btn-container">
          <button
            className="btn draft"
            onClick={(event) => {
              props.handleButtonSubmit(event, 1);
            }}
          >
            Save As Draft
          </button>
          <button
            className="btn create"
            onClick={(event) => {
              props.handleButtonSubmit(event, 2);
            }}
          >
            Create Workout
          </button>
        </div>
      )}

      {props.id && (
        <div className="btn-container">
          <button
            className="btn draft"
            onClick={(event) => {
              props.handleButtonSubmit(event, 1);
            }}
          >
            Save
          </button>
          <button
            className="btn draft"
            onClick={(event) => {
              props.handleButtonSubmit(event, 2);
            }}
          >
            Create
          </button>
          <button
            className="btn create"
            onClick={(event) => {
              props.history.push("/workout");
            }}
          >
            Close
          </button>
        </div>
      )}
    </>
  );
}
