import React from "react";

export default function addRounds({ roundLength, handleAddRoundClick }) {
  return (
    <>
      {roundLength < 15 && (
        <div className="clearfix">
          <div
            onClick={handleAddRoundClick}
            className="pull-right btn add-round-btn"
          >
            <span>+ Add Round</span>
          </div>
        </div>
      )}
    </>
  );
}
