import React from "react";
import { handleActivityDurationDifference } from "../../utils/calculateDuartionDifference";

export default function ActivityErrorMessage({ data }) {
  const durationDiff = handleActivityDurationDifference(data);
  return (
    <>
      <span className="error-message">
        <small>{durationDiff < 0 && "Duration doesn't  match"}</small>
      </span>
      {data &&
      data?.duration > 0 &&
      data.activity_info?.length > 0 &&
      durationDiff > 0 ? (
        <div>
          Remaining Duration : <b>{durationDiff} Sec</b>
        </div>
      ) : null}
    </>
  );
}
