import React from "react";
import config from "../../config";
import ColDetails from "./colDetails";

export default function workoutDuration({ data }) {
  return (
    <>
      <div className="row">
        <ColDetails
          label="Workout Type"
          value={config.workoutTypeIdObj[data.workoutTypeId]}
        />

        <ColDetails
          label="Round Difficulty"
          value={config.roundDifficultyObj[data.roundDifficulty]}
        />
      </div>

      <div className="row">
        <ColDetails label="Duration (Min)" value={`${data.duration} mins`} />
        <ColDetails
          label="Cooldown Time (Sec)"
          value={`${data.coolDownTime} mins`}
        />

        <ColDetails
          label="Warmup Time (Sec)"
          value={`${data.warmUpTime} mins`}
        />
      </div>
    </>
  );
}
