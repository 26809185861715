import React from "react";
import { Link } from "react-router-dom";

export default function liveUrlLinks({
  workoutSummary,
  streamKey,
  liveContentUrl,
  vodContentUrl,
}) {
  return (
    <div className="col-sm-12 col-md-4 ">
      <div className="left-list">
        <div className="table-responsive">
          <table className="table table-sm table-sm-details">
            <tbody>
              <tr>
                <td>Users Attended</td>
                <td>
                  {workoutSummary.usersAttended
                    ? workoutSummary.usersAttended
                    : 0}
                </td>
              </tr>
              <tr>
                <td>Stream key</td>
                <td>{streamKey}</td>
              </tr>
              <tr>
                <td>Live Url</td>
                <td>
                  <Link
                    to={{
                      pathname: liveContentUrl ? liveContentUrl : "/",
                    }}
                    target="_blank"
                  >
                    Click Here
                  </Link>
                </td>
              </tr>
              <tr>
                <td>VOD Url</td>
                <td>
                  <Link
                    to={{
                      pathname: vodContentUrl ? vodContentUrl : "/",
                    }}
                    target="_blank"
                  >
                    Click Here
                  </Link>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
