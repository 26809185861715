import React, { useState } from "react";
import "./TrainerForm.css";
import { createTrainer } from "../../services/userService";
import "../../common/style.css";
import "../LoginForm/loginComponent.css";
import EmailField from "../LoginForm/emailField";
import SubmitButton from "../LoginForm/submitButton";
import useLoginForm from "../../hooks/useLoginForm";
import FormComponent from "../LoginForm/formComponent";
import NameField from "./nameField";

const TrainerForm = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [pageErrors, setPageErrors] = useState(null);

  const [data, errors, handleChange, handleSubmitValidations] =
    useLoginForm(true);
  const handleRegister = () => {
    props.history.push("/login");
  };

  const handleVal = (e) => {
    e.preventDefault();
    let {
      passwordError,
      emailError,
      firstNameError,
      lastNameError,
      confirmPasswordError,
    } = { ...errors };
    let { firstName, lastName, password, email, confirmPassword } = data;
    let isValid = true;
    if (firstNameError || !firstName) {
      firstNameError = firstNameError || "Please Enter First Name";
      isValid = false;
    }
    if (lastNameError || !lastName) {
      lastNameError = lastNameError || "Please Enter Last Name";
      isValid = false;
    }
    if (emailError || !email) {
      emailError = emailError || "Please Enter Eamil ID";
      isValid = false;
    }
    if (passwordError || !password) {
      passwordError = passwordError || "Please Enter Password";
      isValid = false;
    }

    if (confirmPasswordError || !confirmPassword) {
      confirmPasswordError = confirmPasswordError || "Please Confirm Password";
      isValid = false;
    }
    if (isValid) {
      handleButtonSubmit();
    } else {
      handleSubmitValidations(
        passwordError,
        emailError,
        firstNameError,
        lastNameError,
        confirmPasswordError
      );
    }
  };

  const handleButtonSubmit = () => {
    let { firstName, lastName, password, email, playFabId } = data;
    setIsLoading(true);
    let requestBody = {
      email,
      firstName,
      lastName,
      password,
      playFabId,
    };
    setIsLoading(true);
    createTrainer(requestBody)
      .then((res) => {
        setIsLoading(false);
        if (res && res.code !== 200) {
          setPageErrors(res.message);
          window.scrollTo(0, 0);
        } else {
          props.history.push("/login");
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("error", err);
      });
  };

  const { firstName, lastName, password, email, confirmPassword } = data;
  const {
    emailError,
    passwordError,
    confirmPasswordError,
    firstNameError,
    lastNameError,
  } = errors;
  return (
    <FormComponent
      isLoading={isLoading}
      handleSubmit={handleVal}
      pageErrors={pageErrors}
      formClassNames="logo logo-container"
    >
      <NameField
        firstName={firstName}
        lastName={lastName}
        firstNameError={firstNameError}
        lastNameError={lastNameError}
        handleChange={handleChange}
      />
      <EmailField
        name="email"
        username={email}
        password={password}
        confirmPassword={confirmPassword}
        handleChange={handleChange}
        usernameError={emailError}
        passwordError={passwordError}
        confirmPasswordError={confirmPasswordError}
        registerAutoComplete={false}
      />
      <SubmitButton
        isLoading={isLoading}
        submitBtnText="create account"
        backBtnText="back"
        handleRegister={handleRegister}
        btnClassName="btn-sign btn-lg btn-block text-uppercase"
        registerBtn={true}
      />
    </FormComponent>
  );
};

export default TrainerForm;
