import React from "react";
import { Link } from "react-router-dom";
import "./cardComponent.css";
import config from "../../config";
import { deleteWorkout } from "../../services/userService";
import { convertDateAddOffset } from "../../utils/convertTimeZone"
import { Component } from "react";
import { withRouter } from "react-router";
import Modal from "../../common/modal";

class CardComponent extends Component {
  state = {
    Modal: {
      show: false,
      msg: "Are you sure you want to delete this workout?",
      title: "Delete Workout",
      confirm: false,
    },
  };

  handleModalClose = () => {
    this.setState((prevState) => ({
      Modal: { ...prevState.Modal, show: false },
    }));
  };

  handleModalOpen = () => {
    this.setState((prevState) => ({
      Modal: { ...prevState.Modal, show: true },
    }));
  };

  handleAction = (confirmation) => {
    const requestBody = {
      id: this.props.item.workoutId,
    };
    this.handleModalClose();
    if (confirmation) {
      deleteWorkout(requestBody).then((res) => {
        if (res && res.code !== 200) {
          this.setState({
            pageErrors: res.message,
          });
          window.scrollTo(0, 0);
        } else {
          this.props.history.push("/");
        }
      });
    }
  };

  // link =
  //   this.props.type === 2
  //     ? `/workout/edit/${this.props.item.workoutId}`
  //     : `/workout/details/${this.props.item.workoutId}`;
  render() {
    let link;
    if (
      this.props.type === 3 &&
      new Date().getTime() >=
        convertDateAddOffset(new Date(this.props.item.startsAt)).getTime() &&
      new Date().getTime() <=
        convertDateAddOffset(new Date(this.props.item.startsAt)).getTime() +
          this.props.item.duration * 1000
    ) {
      link = `/workout/live/${this.props.item.workoutId}`;
    } else if (this.props.type === 2) {
      link = `/workout/edit/${this.props.item.workoutId}`;
    } else {
      link = `/workout/details/${this.props.item.workoutId}`;
    }
    const { show, title, msg } = this.state.Modal;
    return (
      <>
        <Modal
          title={title}
          msg={msg}
          handleAction={this.handleAction}
          handleModalClose={this.handleModalClose}
          show={show}
        />
        <div className="card">
          <div className="card-body box-shadow-hover">
            <Link to={link}>
              <div className="card-img">
                {config.workoutLevel[this.props.item.mmaVal] === true && (
                  <em className="card-top-badge left"></em>
                )}
                <em className="card-top-badge right">
                  {config.workoutLevel[this.props.item.levelId]}
                </em>

                <img
                  className="imageStyle"
                  src={this.props.item.posterUrl}
                  alt=""
                  width="100%"
                  height="50"
                />
              </div>
              <span className="cardBottomText">
                {parseInt(this.props.item.duration / 60)} mins{" "}
                {this.props.item.duration % 60} sec |{" "}
                {config.padType[this.props.item.padTypeId]}
                <br />
                {/* <span className="cardBelowBottom">
                Pad Type: 
              </span> */}
              </span>
            </Link>
            {this.props.item.isRecordingInProgress === false && (
              <div className="card-delete" onClick={this.handleModalOpen}>
                Delete
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(CardComponent);
