import React, { useState, useEffect, useRef, useCallback } from "react";
import { withRouter } from "react-router-dom";
import config from "../../config";
import { Header } from "./header";
import { TabletView } from "./tablet-view";
import { RoundDetails } from "./round-details";
import { Leaderboard } from "./leaderboard";
import "./dashboardPage.css";

function LiveDashboard({
  workoutsDetails,
  startTime,
  history,
  leaderBoard,
  trainerStrikes,
  handleSortPower,
  sortName,
  sortDirection,
  activityList,
  channelName,
  uid
}) {
  const requestRef = useRef();
  const [timer, setTimer] = useState(0);
  const [roundSchedule, setRoundSchedule] = useState(null);
  const [activitySchedule, setActivitySchedule] = useState(null);
  const [roundCount, setRoundCount] = useState(0);
  const [currentRound, setCurrentRound] = useState(null);
  const [currentActivity, setCurrentActivity] = useState(null);
  const [nextRound, setNextRound] = useState(null);
  const [nextActivity, setNextActivity] = useState(null);
  const [nextNextActivity, setNextNextActivity] = useState(null);

  /* Parse workout details into object with names and end times of round and activities */
  useEffect(() => {
    if (!workoutsDetails || !workoutsDetails.rounds || !activityList || Object.keys(activityList) <= 0) return;

    let totalTime = 0;
    let tempActivitySchedule = [];
    let tempRoundSchedule = [];

    setRoundCount(workoutsDetails.rounds.length);

    workoutsDetails.rounds.forEach((round, i) => {
      const roundName = config.workoutTypeId[round.workoutTypeId - 1].name;
      let startTime = totalTime;

      totalTime += round.warmUpTime;
      tempActivitySchedule.push({
        endTime: totalTime,
        name: 'Warm Up',
        duration: round.warmUpTime
      });

      if (round.Activities.length > 0) {
        round.Activities.forEach(activity => {
          totalTime += activity.durationSec;
          tempActivitySchedule.push({
            endTime: totalTime,
            name: activityList[roundName][activity.activityListId].name,
            duration: activity.durationSec
          });
        });
      } else {
        totalTime += round.duration * 60;
        tempActivitySchedule.push({
          endTime: totalTime,
          name: roundName,
          duration: round.duration * 60
        });
      }

      totalTime += round.coolDownTime;
      tempActivitySchedule.push({
        endTime: totalTime,
        name: 'Cool Down',
        duration: round.coolDownTime
      });

      const notes = round.Activities.length > 0 ?
        round.Activities.map((activity, i) => <div key={i}>{activityList[roundName][activity.activityListId].name} {activity.durationSec}s</div>)
        : ['N/A'];

      tempRoundSchedule.push({
        endTime: totalTime,
        duration: totalTime - startTime,
        name: roundName,
        position: i + 1,
        notes: notes
      });
    });

    setRoundSchedule(tempRoundSchedule);
    setActivitySchedule(tempActivitySchedule);
  }, [workoutsDetails, activityList]);


  /* Timer functionality */
  const update = useCallback(() => {
    requestRef.current = requestAnimationFrame(update)
    if (!startTime) {
      return;
    }
    const timeInMs = new Date() - startTime;
    const timeInS = timeInMs / 1000;
    setTimer(timeInS);
  }, [startTime])

  /* Start timer */
  useEffect(() => {
    requestRef.current = requestAnimationFrame(update)
    return () => cancelAnimationFrame(requestRef.current)
  }, [update])

  /* Get current round and activity based on above timer and schedules */
  useEffect(() => {
    if (!timer || !roundSchedule || !activitySchedule) return;

    let foundActivity = activitySchedule.findIndex(activity => activity.endTime >= timer);
    setCurrentActivity(activitySchedule[foundActivity]);
    if (foundActivity >= activitySchedule.length - 1) setNextActivity(null);
    else setNextActivity(activitySchedule[foundActivity + 1]);
    if (foundActivity >= activitySchedule.length - 2) setNextNextActivity(null);
    else setNextNextActivity(activitySchedule[foundActivity + 2]);

    let foundRound = roundSchedule.findIndex(round => round.endTime >= timer);
    setCurrentRound(roundSchedule[foundRound]);
    if (foundRound >= roundSchedule.length - 1) setNextRound(null);
    else setNextRound(roundSchedule[foundRound + 1]);
  }, [timer, roundSchedule, activitySchedule]);

  /* Change page if workout is over */
  useEffect(() => {
    if (!roundSchedule) return;

    if (timer > roundSchedule[roundSchedule.length - 1].endTime) history.push(`/workout/details/${workoutsDetails.workoutId}`);
  }, [timer, history, roundSchedule, workoutsDetails]);

  return (
    <div className="live-container row" style={{ margin: 0 }}>
      <Header
        workoutsDetails={workoutsDetails}
        timer={timer}
        currentRound={currentRound}
        nextRound={nextRound}
        currentActivity={currentActivity}
        nextActivity={nextActivity}
        roundSchedule={roundSchedule}
        roundCount={roundCount}
      />
      <div className="main-dashboard">
        <TabletView 
          liveContentUrl={workoutsDetails.liveContentUrl}
        />
        <RoundDetails
          timer={timer}
          currentRound={currentRound}
          nextRound={nextRound}
          currentActivity={currentActivity}
          nextActivity={nextActivity}
          nextNextActivity={nextNextActivity}
          roundCount={roundCount}
        />
        <Leaderboard
          sortName={sortName}
          sortDirection={sortDirection}
          leaderBoard={leaderBoard}
          trainerStrikes={trainerStrikes}
          handleSortPower={handleSortPower}
        />
      </div>
    </div>
  );
}

export default withRouter(LiveDashboard);