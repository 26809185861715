export default {
  applicationUrl: process.env.REACT_APP_SERVICE_URL,
  config: {
    getConfig: process.env.REACT_APP_SERVICE_URL + "/common/config",
  },
  auth: {
    login: process.env.REACT_APP_SERVICE_URL + "/Login/portal/perform",
    logout: "",
  },
  workout: {
    createWorkout: process.env.REACT_APP_SERVICE_URL + "/Workout/create",
    saveToDraft: process.env.REACT_APP_SERVICE_URL + "/Workout/save_as_draft",
    listWorkout: process.env.REACT_APP_SERVICE_URL + "/Workout/list",
    uploadPoster: process.env.REACT_APP_SERVICE_URL + "/Common/upload_poster",
    updatePoster: process.env.REACT_APP_SERVICE_URL + "/Workout/update/workoutposter",
    strikesList: process.env.REACT_APP_SERVICE_URL + "/Common/strikes_list",
    activityList: process.env.REACT_APP_SERVICE_URL + "/Common/acitivity_list",
    combosList: process.env.REACT_APP_SERVICE_URL + "/Common/combos_list",
    moves_list: process.env.REACT_APP_SERVICE_URL + "/Common/moves_list",
    workoutDetails: process.env.REACT_APP_SERVICE_URL + "/Workout/workout_detail",
    refreshToken: process.env.REACT_APP_SERVICE_URL + "/Login/refreshtoken",
    deleteWorkout: process.env.REACT_APP_SERVICE_URL + "/Workout/delete",
    createTrainer: process.env.REACT_APP_SERVICE_URL + "/Registration/trainer/perform",
    updatePermissions: process.env.REACT_APP_SERVICE_URL + "/User/update_user_role",
    searchUser: process.env.REACT_APP_SERVICE_URL + "/User/user_list"
  },
  workoutTypeId: [
    {
      id: 1,
      name: "Cardio",
    },
    {
      id: 2,
      name: "Strike",
    },
    {
      id: 3,
      name: "Mitts",
    },
    {
      id: 4,
      name: "Sparring",
    },
    {
      id: 5,
      name: "Core",
    },
    {
      id: 6,
      name: "Tutorial",
      isMultiSelect: true,
      selected: true,
    },
  ],
  workoutTypeList: [
    {
      id: 1,
      name: "Cardio",
      isMultiSelect: true,
      selected: true,
    },
    {
      id: 2,
      name: "Strike",
      isMultiSelect: true,
      selected: false,
    },
    {
      id: 3,
      name: "Mitts",
      isMultiSelect: true,
      selected: false,
    },
    {
      id: 4,
      name: "Sparring",
      isMultiSelect: true,
      selected: false,
    },
    {
      id: 5,
      name: "Core",
      isMultiSelect: true,
      selected: false,
    },
    {
      id: 6,
      name: "Tutorial",
      isMultiSelect: true,
      selected: true,
    },
  ],
  workoutLevel: {
    1: "Beginner",
    2: "Intermediate",
    3: "Advanced",
  },
  workoutLevelList: [
    {
      id: 1,
      value: "Beginner",
      name: "Beginner",
    },
    {
      id: 2,
      value: "Intermediate",
      name: "Intermediate",
    },
    {
      id: 3,
      value: "Advanced",
      name: "Advanced",
    },
  ],
  activityList: [
    {
      id: 1,
      value: "Jumping Jacks",
      name: "Jumping Jacks",
    },
    {
      id: 2,
      value: "High Knee",
      name: "High Knee",
    },
    {
      id: 3,
      value: "Mitts",
      name: "Mitts",
    },
    {
      id: 4,
      value: "Sparring",
      name: "Sparring",
    },
    {
      id: 5,
      value: "Core",
      name: "Core",
    },
  ],
  activityTypeId: [
    {
      id: 1,
      name: "Jumping Jacks",
    },
    {
      id: 2,
      name: "High Knee",
    },
    {
      id: 3,
      name: "Mitts",
    },
    {
      id: 4,
      name: "Sparring",
    },
    {
      id: 5,
      name: "Core",
    },
  ],
  padType: {
    1: "3 Pads",
    2: "5 Pads",
  },
  moves: {
    1: "Left Step",
    2: "Right Step",
    3: "Left Slip",
    4: "Right Slip",
    5: "Left Bob",
    6: "Right Bob",
  },
  Roles: {
    1: "System Admin",
    2: "Admin",
    3: "Trainer",
    4: "Trainee",
  },
  roundDifficulty: [
    {
      id: 1,
      name: "Normal",
      duration: 3,
      cooldown: 30,
      warmup: 30,
    },
    {
      id: 2,
      name: "Hard",
      duration: 3,
      cooldown: 15,
      warmup: 15,
    },
  ],
  workoutTypeIdObj: {
    1: "Cardio",
    2: "Strike",
    3: "Mitts",
    4: "Sparring",
    5: "Core",
    6: "Tutorial",
  },
  roundDifficultyObj: {
    1: "Normal",
    2: "Hard",
  },
  roundError: {
    levelError: "",
    workoutTypeError: "",
    roundDifficultyError: "",
    coolDownError: "",
    warmUpError: "",
    durationError: "",
    activityError: [],
    combosError: "",
    strikesError: "",
    movesError: "",
  },
  workoutInitial: [{
    id: 0,
      workoutTypeId: 0,
      roundDifficulty: 0,
      coolDownTime: 0,
      warmUpTime: 0,
      stanceTypeId: 1,
      duration: 0,
      sequenceNum: 0,
      isDefensive: false,
      disabled: true,
      strikes: {},
      combos: {},
      moves: {},
      activity_info: [],
  }],
  workoutErrorInitial: [{
      levelError: "",
      workoutTypeError: "",
      roundDifficultyError: "",
      coolDownError: "",
      warmUpError: "",
      durationError: "",
      activityError: [],
      combosError: "",
      strikesError: "",
      movesError: "",
    }]
};

export const workOutCreate = (id, seqNum) => {
  return {
    id: id,
    workoutTypeId: 0,
    roundDifficulty: 0,
    coolDownTime: 0,
    warmUpTime: 0,
    stanceTypeId: 1,
    duration: 0,
    sequenceNum: seqNum,
    activity_info: [],
    isDefensive: false,
    disabled: true,
    strikes: {},
    combos: {},
    moves: {},
  };
};
