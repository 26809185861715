// eslint-disable-next-line
export const validEmailRegex = RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/);
export const validCharactersRegex = RegExp(/^[A-Za-z]+$/);
export const phoneNumberRegex = RegExp(/^([0-9]{10})$/);
export const checkForEmptyString = RegExp(/^[^\s]+(\s+[^\s]+)*$/);

export const getRoundStructure = (item, combos, strikes, moves) => {
  return {
    id: item.roundId,
    workoutTypeId: item.workoutTypeId,
    roundDifficulty: item.roundDifficulty,
    coolDownTime: item.coolDownTime,
    warmUpTime: item.warmUpTime,
    stanceTypeId: item.stanceTypeId,
    duration: item.duration,
    sequenceNum: item.sequenceNum,
    activity_info: item.Activities,
    combos: combos,
    strikes: strikes,
    moves: moves,
    disabled: !item.isDefensive,
    isDefensive: item.isDefensive,
  };
};

export const isStrike = (workoutType) =>
  workoutType === "Mitts" || workoutType === "Sparring";

export const isActivity = (workoutType) =>
  workoutType === "Cardio" ||
  workoutType === "Strike" ||
  workoutType === "Core";
