import React from "react";
import { Button } from "react-bootstrap";

export default function deleteWorkoutInfo({ title, handleModalOpen }) {
  return (
    <div className="col-sm-12">
      <h2 className=" page-heading">{title} a Workout</h2>
      {title === "Edit" && (
        <div className="delete-button-position-edit">
          <Button
            variant="outline-danger"
            onClick={handleModalOpen}
          >
            Delete
          </Button>
        </div>
      )}
    </div>
  );
}
