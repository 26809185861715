import React from "react";
import config from "../../config";

export default function activityDetails({
  activities,
  activityList,
  workoutTypeId,
}) {
  const isEmpty = (obj) => {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  };
  if (activities && !isEmpty(activityList) && activities?.length > 0) {
    const activityListNames =
      activityList[config.workoutTypeIdObj[workoutTypeId]];
    return activities.map((data, index) => (
      <div className="row" key={index}>
        <div className="col-sm-6">
          <div className="each-detail-duration">
            {activityListNames && activityListNames[data.activityListId]?.name}
          </div>
        </div>
        <div className="col-sm-6">
          <div className="each-detail-duration">{data.durationSec} secs</div>
        </div>
      </div>
    ));
  }
  return "-";
}
